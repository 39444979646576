import { Button, Drawer, Popconfirm, Space, Table, Typography } from "antd";
import React, { useState } from "react";
import useSWR from "swr";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import Search from "antd/es/input/Search";
import { fetcher } from "../../services/fectcher";
import { usePickList } from "../../services/queries";
import { WarningOutlined } from "@ant-design/icons";
import { useMyContext } from "../../common/elements/myContext";
import HospiceEdit from "./HospiceEdit";
import { deleteHospice } from "../../api/ToCareApi";
import { MdEventNote } from "react-icons/md";
import { openNotification } from "../../common/elements/myNotification";
import { handleToggle } from "../../common/elements/toggleSeeMore";

const HospiceView = (props) => {
  const intialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid,
    notificationSource: "",
    notificationSourceOtherNotes: "",
    agencyName: "",
    referringPhysician: "",
    hospiceDx: "",
    startDate: "",
    endDate: "",
    deceasedDate: "",
    dischargeDate: "",
    dischargeReason: "",
    dischargeReasonOtherNotes: "",
    createdBy: sessionStorage.getItem("userName") || "",
    modifiedBy: "",
  };

  const { calculateDateRange } = useMyContext();
  const { fromDate, toDate } = calculateDateRange();

  const sonHospiceOptions = usePickList(13).data;
  const ddHospiceOptions = usePickList(20).data;

  const notificationSourceLabel = (notificationSource) => {
    const notifValue = sonHospiceOptions?.find(
      (item) => item.value === notificationSource
    );
    return notifValue ? notifValue.label : "";
  };
  const dischargeDispositionLabel = (dischargeReason) => {
    const dischargeValue = ddHospiceOptions?.find(
      (item) => item.value === dischargeReason
    );
    return dischargeValue ? dischargeValue.label : "";
  };

  const [editRecord, setEditRecord] = useState(intialReqState);
  const [query, setQuery] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `getHospiceForDateRange?mbi=${props.mbiid}&fromDate=${fromDate}&toDate=${toDate}`,
    fetcher
  );

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const edit = (record) => {
    console.log("record", record);
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleOk = async (e) => {
    console.log(e);
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };
  const handleDelete = async (record) => {
    const body = { ...record, modifiedBy: sessionStorage.getItem("userName") };
    const deleteResult = await deleteHospice(body);
    console.log("deleteResult", deleteResult.statusText);
    if (deleteResult.status === 200) {
      mutate();
      openNotification("success", "Deleted", "Hospice deleted successfully!");
    } else {
      openNotification("error", "Error", "Hospice failed to delete.");
    }
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const columns = [
    {
      key: 1,
      title: "Notification Source",
      dataIndex: "notificationSource",
      width: 40,
      render: (_, data) => {
        return (
          <>
            <div>{notificationSourceLabel(data.notificationSource)}</div>
            {(data.notificationSource === "Other" ||
              data.notificationSource === "HIE_-_Other") && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.notificationSourceOtherNotes}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 2,
      title: "Agency Name",
      dataIndex: "agencyName",
      width: 30,
    },
    {
      key: 3,
      title: "Referring Physician",
      dataIndex: "referringPhysician",
      width: 30,
    },
    {
      key: 3,
      title: "Hospice Dx",
      dataIndex: "hospiceDx",
      width: 200,
      render: (text, record) => {
        const isExpanded = expandedRows.includes(record.key);
        const content =
          text.length > 100 ? (
            <span>
              {isExpanded ? text : `${text.substring(0, 100)}...`}
              <Button
                type="link"
                onClick={() => handleToggle(record.key, setExpandedRows)}
              >
                {isExpanded ? "See less" : "See more"}
              </Button>
            </span>
          ) : (
            text
          );

        return <div>{content}</div>;
      },
    },
    {
      key: 5,
      title: "Start Date",
      dataIndex: "startDate",
      width: 30,
    },
    {
      key: 5,
      title: "End Date",
      dataIndex: "endDate",
      width: 30,
    },
    {
      key: 5,
      title: "Discharge Date",
      dataIndex: "dischargeDate",
      width: 30,
    },
    {
      key: 5,
      title: "Deceased Date",
      dataIndex: "deceasedDate",
      width: 30,
    },
    {
      key: 6,
      title: "Patient Disposition",
      dataIndex: "dischargeReason",
      width: 30,
      render: (_, data) => {
        return (
          <>
            <div>{dischargeDispositionLabel(data.dischargeReason)}</div>
            {data.dischargeReason === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.dischargeReasonOtherNotes}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      width: 10,
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Typography.Link onClick={() => edit(record)}>Edit</Typography.Link>
          <Typography.Link type="danger">
            <Popconfirm
              title="Ok to Delete?"
              onConfirm={() => handleDelete(record)}
              icon={
                <WarningOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
            >
              Delete
            </Popconfirm>
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "notificationSource",
          "notificationSourceOtherNotes",
          "agencyName",
          "referringPhysician",
          "hospiceDx",
          "startDate",
          "endDate",
          "deceasedDate",
          "dischargeDate",
          "dischargeReason",
          "dischargeReasonOtherNotes",
          "createdBy",
        ].some((key) => {
          if (key === "notificationSource") {
            const normalizedQuery =
              query?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            const sourceValue =
              item[key]?.toString()?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            return sourceValue.includes(normalizedQuery);
          } else if (key === "dischargeReason") {
            const normalizedQuery =
              query?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            const sourceValue =
              item[key]?.toString()?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            return sourceValue.includes(normalizedQuery);
          } else {
            return (item[key]?.toString()?.toLowerCase() ?? "").includes(
              query?.toLowerCase() ?? ""
            );
          }
        });
      })
    : data;

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          Hospice
        </p>

        {isLoading && <MyBackdropSpinner show={isLoading} />}

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4"
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          title="Hospice"
          width={800}
          onClose={handleCancel}
          open={isModalOpen}
        >
          <HospiceEdit
            mbiid={props.mbiid}
            patient={props.patient}
            editRecord={editRecord}
            callback={handleOk}
            son={sonHospiceOptions}
            ddo={ddHospiceOptions}
          />
        </Drawer>
      )}

      <Table
        bordered
        size="middle"
        dataSource={finalResult}
        columns={columns}
      />
    </div>
  );
};

export default HospiceView;
