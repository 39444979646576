import { DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import dayjs from "dayjs";
import { useMyContext } from "../../common/elements/myContext";
import { postCCN } from "../../api/CareEventsApi";
import { openNotification } from "../../common/elements/myNotification";

export const AddChangeinCondition = ({ editRecord, callback }) => {
  const [formData, setFormData] = useState(
    editRecord.guid
      ? { ...editRecord, modifiedBy: sessionStorage.getItem("userName") }
      : editRecord
  );
  const [disable, setDisable] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState(
    editRecord.guid ? dayjs(editRecord?.noteDateTime) : dayjs()
  );
  const [pageError, setPageError] = useState({});

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onDateChange = (date, dateString) => {
    console.log("dateString", dateString);
    setSelectedDateTime(date || "");
    setFormData({
      ...formData,
      noteDateTime: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      noteDateTime: dateString ? "" : "Required",
    });
  };
  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (
      !formData?.noteDateTime ||
      !formData?.changeCondition ||
      !formData?.acuteOrChronic
    ) {
      pageErrorTemp.noteDateTime = formData?.noteDateTime ? "" : "Required";
      pageErrorTemp.acuteOrChronic = formData?.acuteOrChronic ? "" : "Required";
      pageErrorTemp.changeCondition = formData?.changeCondition
        ? ""
        : "Required";
      setPageError(pageErrorTemp);
      return;
    }
    onSave(e);
  };
  const onSave = async (e) => {
    setDisable(true);
    try {
      const response = await postCCN(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Change in Condition Note Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Change in Condition Note Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "Change in Condition Note Failed to Add!"
        );
      }
      console.log("response", response);
      setFormData(editRecord);
      setSelectedDateTime(dayjs());
      callback(response);
    } catch (e) {
      return e;
    }
    setDisable(false);
  };

  console.log("formData", formData);

  return (
    <div className="tw-relative">
      {/* <div className="tw-flex tw-flex-col tw-mb-3 ">
        <label className="tw-font-bold" htmlFor="noteType">
          Note Type
        </label>

        <MyCustomSelect
          name="NoteType"
          selectOptions={[{ label: "Note Type", value: "Note Type" }]}
          myCallBack={(e) => {
            setFormData({ ...formData, noteType: e ? e.value : "" });
            setPageError({ ...pageError, noteType: e ? "" : "Required" });
          }}
          selectedValue={
            formData.noteType
              ? [
                  {
                    label: formData?.noteType,
                    value: formData?.noteType,
                  },
                ]
              : ""
          }
          errorMessage={pageError?.noteType}
        />
      </div> */}
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="changeCondition">
          Type of Exacerbation
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="acuteOrChronic"
              checked={formData?.acuteOrChronic === "Acute"}
              id="acuteexc"
              value={"acute"}
              onChange={(e) =>
                handleInputChange(
                  "acuteOrChronic",
                  e.currentTarget.value ? "Acute" : "Chronic"
                )
              }
            />
            <label className="form-check-label" htmlFor="acuteexc">
              Acute
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="acuteOrChronic"
              checked={formData?.acuteOrChronic === "Chronic"}
              id="chronicexc"
              value={"chronic"}
              onChange={(e) =>
                handleInputChange(
                  "acuteOrChronic",
                  e.currentTarget.value ? "Chronic" : "Acute"
                )
              }
            />
            <label className="form-check-label" htmlFor="chronicexc">
              Chronic
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.acuteOrChronic}
          </span>
        </fieldset>
      </div>

      <div className="tw-flex ">
        <label className="tw-font-bold" htmlFor="dateTime">
          Note Date & Time
        </label>
      </div>
      <DatePicker
        showTime
        use12Hours
        className="form-control tw-mb-3"
        onChange={onDateChange}
        format={"YYYY-MM-DD hh:mm A"}
        value={selectedDateTime}
        style={{
          border: pageError?.noteDateTime ? "1px solid red" : "",
        }}
      />
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="changeCondition">
          Change in Condition
        </label>
        <textarea
          maxLength={1000}
          className=" form-control"
          id="changeCondition"
          value={formData?.changeCondition}
          onChange={(e) => handleInputChange("changeCondition", e.target.value)}
          style={{
            border: pageError?.changeCondition ? "1px solid red" : "",
          }}
        ></textarea>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="patientPlan">
          Course/Plan of Treatment
        </label>
        <textarea
          maxLength={1000}
          className=" form-control"
          id="patientPlan"
          value={formData?.patientPlan}
          onChange={(e) => handleInputChange("patientPlan", e.target.value)}
        ></textarea>
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={
            !disable ? "btn btn-success " : "btn btn-success disabled "
          }
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};
