import React, { useState } from "react";
import PageTitle from "../Common/PageTitle";
import MyCustomSelect, {
  formatOptionLabel,
} from "../../common/elements/myCustomSelect";
import {
  Button,
  Table,
  Typography,
  Modal,
  Input,
  Form,
  Select,
  Skeleton,
  Popconfirm,
} from "antd";
import Search from "antd/es/input/Search";
import TextArea from "antd/es/input/TextArea";
import useSWR from "swr";
import { fetcher } from "../../services/fectcher";
import useSWRImmutable from "swr/immutable";
import { usePickList } from "../../services/queries";
import { postDashBoard } from "../../api/CaseMgmtDashboardApi";
import { openNotification } from "../../common/elements/myNotification";
import { WarningOutlined } from "@ant-design/icons";

const CaseManagementDashboard = () => {
  const intialReqState = {
    key: "",
    guid: "",
    mbi: "",
    patientNameWithDOB: "",
    dateOfBirth: "",
    age: "",
    gender: "",
    facilityName: "",
    ccmdProvider: "",
    utilisation: "",
    codeStatus: "",
    clinicalNotes: "",
    responsible: "",
    createdBy: sessionStorage.getItem("userName"),
    modifiedBy: sessionStorage.getItem("userName"),
  };

  const [form] = Form.useForm();
  const [formData, setFormData] = useState(intialReqState);

  const [editingKey, setEditingKey] = useState("");
  const [query, setQuery] = useState("");
  const [provider, setProvider] = useState("");
  const [providerGroup, setProviderGroup] = useState("");
  const [cmsProvider, setCMSProvider] = useState("");
  const [codeStat, setCodeStat] = useState("");
  const [freezeEdit, setFreezeEdit] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [isArchivedModalOpen, setIsArchivedModalOpen] = useState(false);
  const codeStatusOptions = usePickList(28).data;

  const { data: provList } = useSWRImmutable(`GetCmProviders`, fetcher);
  const { data: provGroupList } = useSWRImmutable(
    `GetCmProviderGroup`,
    fetcher
  );
  const { data: cmsProvList } = useSWRImmutable(
    `getcmsAlignedProvider`,
    fetcher
  );

  const {
    data: fetchedIndata,
    mutate,
    isLoading,
  } = useSWR(
    provider || providerGroup || cmsProvider
      ? `GetCmProviderGrpRecords?providerGroup=${
          providerGroup || "All"}&Provider=${provider || "All"}&cmsProvider=${cmsProvider || "All"}&status=All`
      : null,
    fetcher
  );

  const {
    data: archivedData,
    mutate: archiveMutate,
    isLoading: archiveLoading,
  } = useSWR(
    (provider || providerGroup || cmsProvider) && isArchivedModalOpen
      ? `GetCmProviderGrpRecords?providerGroup=${
          providerGroup || "All"
        }&Provider=${provider || "All"}&cmsProvider=${cmsProvider || "All"}&status=Archived`
      : null,
    fetcher
  );

  const onProviderChange = (e) => {
    setProvider(e);
  };

  const onProviderGroupChange = (e) => {
    setProviderGroup(e);
  };

  const onCMSProviderChange = (e) => {
    setCMSProvider(e);
  };

  const openArchiveTable = () => {
    setIsArchivedModalOpen(true);
  };

  const handleEdit = (record) => {
    setFormData({
      ...formData,
      ...record,
      key: "",
      guid: "",
      createdBy: sessionStorage.getItem("userName"),
      modifiedBy: sessionStorage.getItem("userName"),
    });
    setEditingKey(record.key);
    form.setFieldsValue(record);

    setCodeStat("");
    setFreezeEdit(true);

    if (record?.guid) {
      let codeTemp = [];
      if (record?.codeStatus) {
        codeTemp = codeStatusOptions?.filter((item) => {
          return item.value === record?.codeStatus;
        });
        setCodeStat(codeTemp[0]);
      }
    }
  };

  const handleCancel = () => {
    setFormData(intialReqState);
    setEditingKey("");
    form.resetFields();
    setFreezeEdit(false);
  };

  const onSubmit = async () => {
    const row = await form.validateFields();
    const finalData = { ...formData, ...row };
    setFormData(finalData);
    if (
      !finalData?.codeStatus ||
      !finalData?.clinicalNotes ||
      !finalData?.responsible
    ) {
      const missingFields = [];
      if (!finalData?.codeStatus) {
        missingFields.push("Code Status");
      }
      if (!finalData?.clinicalNotes) {
        missingFields.push("Clinical Notes");
      }
      if (!finalData?.responsible) {
        missingFields.push("Responsible");
      }

      const missingFieldsDivs = missingFields.map((field, index) => (
        <div key={index}>- {field}</div>
      ));

      openNotification(
        "warning",
        "Warning",
        <div>
          <p>Please fill all mandatory fields:</p>
          {missingFieldsDivs}
        </div>
      );
      return;
    } else {
      try {
        setDisableButton(true);
        const response = await postDashBoard(finalData);
        if (!formData?.guid) {
          if (response?.status === 200) {
            openNotification(
              "success",
              "Added",
              "Patient Record Updated Successfully!"
            );
          } else {
            openNotification("error", "Error", "Patient Record failed to Add");
          }
          mutate();
          archiveMutate();
        } else {
          openNotification(
            "success",
            "Updated",
            "Patient Record Updated Successfully!"
          );
        }

        handleCancel();
      } catch (errInfo) {
        console.log("Validation Failed:", errInfo);
      } finally {
        setDisableButton(false);
        setFreezeEdit(false);
      }
    }
  };

  const onArchive = async (record) => {
    const updatedFormData = {
      ...formData,
      ...record,
      key:
        record.key === null || record.key === undefined
          ? ""
          : String(record.key),
      guid:
        record.guid === null || record.guid === undefined
          ? ""
          : String(record.guid),
      clinicalNotes:
        record.clinicalNotes === null || record.clinicalNotes === undefined
          ? ""
          : record.clinicalNotes,
      responsible:
        record.responsible === null || record.responsible === undefined
          ? ""
          : record.responsible,
      status: "Archived",
    };
    const response = await postDashBoard(updatedFormData);
    if (response?.status === 200) {
      openNotification(
        "success",
        "Added",
        "Patient Record Archived Successfully!"
      );
      mutate();
      archiveMutate();
    } else {
      openNotification("error", "Error", "Patient Record failed to Archive");
    }
  };

  const onUnArchive = async (record) => {
    const updatedFormData = {
      ...record,
      status: "NotArchived",
      modifiedBy: sessionStorage.getItem("userName"),
      createdBy: sessionStorage.getItem("userName"),
    };
    const response = await postDashBoard(updatedFormData);
    if (response?.status === 200) {
      openNotification(
        "success",
        "Added",
        "Patient Record Reactivated Successfully!"
      );
      archiveMutate();
      mutate();
    } else {
      openNotification("error", "Error", "Patient Record failed to Reactivate");
    }
  };

  const columns = [
    {
      title: "Patient Name / Age / Gender",
      dataIndex: "patientNameWithDOB",
      key: "patientNameWithDOB",
      width: 350,
      render: (text, record) => (
        <>
          <div className="tw-font-medium">
            {formatOptionLabel({ label: record?.patientNameWithDOB })}
          </div>
          {record.gender === "Male" ? (
            <p className="tw-font-medium tw-mb-0 tw-text-gray-500">
              {record.age} / M
            </p>
          ) : (
            <p className="tw-font-medium tw-mb-0 tw-text-gray-500">
              {record.age} / F
            </p>
          )}
        </>
      ),
    },

    {
      title: "Facility",
      dataIndex: "facilityName",
      key: "facilityName",
      width: 200,
    },
    {
      title: "CCMD Provider",
      dataIndex: "ccmdProvider",
      key: "ccmdProvider",
      width: 175,
    },
    {
      title: "Code Status",
      dataIndex: "codeStatus",
      key: "codeStatus",
      editable: true,
      width: 200,
      render: (text) => <span>{text.replace(/_/g, "-")}</span>,
    },
    {
      title: "Type of Utilizations",
      dataIndex: "utilisation",
      key: "utilisation",
      width: 1000,
      render: (_, { utilisation }) => {
        return utilisation
          .split(",")
          .map((item, index) => <div key={index}>{item.trim()}</div>);
      },
    },
    {
      title: "Clinical Notes",
      dataIndex: "clinicalNotes",
      key: "clinicalNotes",
      editable: true,
      width: 300,
    },
    {
      title: "Responsible",
      dataIndex: "responsible",
      key: "responsible",
      editable: true,
      width: 200,
    },
    {
      title: "Operation",
      dataIndex: "guid",
      width: 130,
      fixed: "right",
      render: (_, record) =>
        editingKey === record.key ? (
          <div className="tw-space-x-6">
            <Typography.Link disabled={disableButton} onClick={onSubmit}>
              Save
            </Typography.Link>
            <Typography.Link onClick={handleCancel}>Cancel</Typography.Link>
          </div>
        ) : (
          <div className="tw-space-x-4">
            <Typography.Link onClick={() => handleEdit(record)}>
              Edit
            </Typography.Link>
            <Typography.Link type="danger">
              <Popconfirm
                title="Ok to Archive?"
                onConfirm={() => onArchive(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                Archive
              </Popconfirm>
            </Typography.Link>
          </div>
        ),
    },
  ];

  const archivedColumns = [
    {
      title: "Patient Name / Age / Gender",
      dataIndex: "patientNameWithDOB",
      key: "patientNameWithDOB",
      width: 300,
      render: (text, record) => (
        <>
          <div className="tw-font-medium">
            {formatOptionLabel({ label: record?.patientNameWithDOB })}
          </div>
          {record.gender === "Male" ? (
            <p className="tw-font-medium tw-mb-0 tw-text-gray-500">
              {record.age} / M
            </p>
          ) : (
            <p className="tw-font-medium tw-mb-0 tw-text-gray-500">
              {record.age} / F
            </p>
          )}
        </>
      ),
    },
    {
      title: "Facility",
      dataIndex: "facilityName",
      key: "facilityName",
      width: 250,
    },
    {
      title: "CCMD Provider",
      dataIndex: "ccmdProvider",
      key: "ccmdProvider",
      width: 150,
    },
    {
      title: "Operation",
      dataIndex: "guid",
      width: 120,
      fixed: "right",
      render: (_, record) => (
        <div className="tw-space-x-6">
          <Typography.Link onClick={() => onUnArchive(record)}>
            Reactivate
          </Typography.Link>
        </div>
      ),
    },
  ];

  const finalResult = fetchedIndata
    ? fetchedIndata
        .filter((item) => {
          return [
            "patientNameWithDOB",
            "mbi",
            "dateOfBirth",
            "age",
            "gender",
            "facilityName",
            "ccmdProvider",
            "utilisation",
            "codeStatus",
            "clinicalNotes",
            "responsible",
          ].some((key) => {
            const value = item[key]?.toString()?.toLowerCase() ?? "";
            const transformedQuery =
              key === "codeStatus"
                ? (query?.toLowerCase() ?? "").replace(/-/g, "_")
                : query?.toLowerCase() ?? "";

            return value.includes(transformedQuery);
          });
        })
        .map((item, index) => ({ ...item, key: item.guid || index }))
    : fetchedIndata;

  const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    ...restProps
  }) => {
    const [editingValue, setEditingValue] = useState(record?.[dataIndex]);

    const handleInputChange = (e) => {
      setEditingValue(e.target.value);
    };

    const handleSelectChange = (value) => {
      setEditingValue(value);
      form.setFieldsValue({ [dataIndex]: value?.value });
      setCodeStat(value);
    };

    const inputNode =
      dataIndex === "clinicalNotes" ? (
        <TextArea
          rows={2}
          value={editingValue}
          maxLength={1000}
          onChange={handleInputChange}
        />
      ) : dataIndex === "codeStatus" ? (
        <MyCustomSelect
          selectOptions={codeStatusOptions}
          selectedValue={codeStat}
          myCallBack={handleSelectChange}
          menuPortalTarget={document.body}
        />
      ) : (
        <Input
          value={editingValue}
          maxLength={100}
          onChange={handleInputChange}
        />
      );

    return (
      <td {...restProps}>
        {editable && formData.mbi === record.mbi ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        key: col.key,
      }),
    };
  });

  return (
    <>
      <div className="tw-flex tw-justify-between">
        <PageTitle title={"HN Provider Group Rounds"} />
        <div className="tw-w-3/4 tw-flex tw-space-x-4 tw-justify-end tw-items-center">
          {/* <div className="tw-w-1/2 ">
              <MyCustomSelect
                selectOptions={provList}
                selectedValue={provider}
                myCallBack={onProviderChange}
                placeholderText={"Select Provider Name..."}
              />
            </div> */}
          <Select
            className="tw-w-1/3"
            showSearch
            allowClear
            disabled={(provider || providerGroup? true : false) || freezeEdit}
            placeholder="Select CMS Provider..."
            optionFilterProp="children"
            onChange={onCMSProviderChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={cmsProvList}
          />
          <Select
            className="tw-w-1/3"
            showSearch
            allowClear
            disabled={(provider || cmsProvider? true : false) || freezeEdit}
            placeholder="Select Provider Group..."
            optionFilterProp="children"
            onChange={onProviderGroupChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={provGroupList}
          />
          <Select
            className="tw-w-1/3"
            showSearch
            allowClear
            disabled={(providerGroup || cmsProvider? true : false) || freezeEdit}
            placeholder="Select Provider Name..."
            optionFilterProp="children"
            onChange={onProviderChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={provList}
          />
          <Search
            size="middle"
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
            allowClear
            className="tw-w-1/4"
          />
          <Button
            type="default"
            size="middle"
            onClick={openArchiveTable}
            disabled={(!provider && !providerGroup && !cmsProvider) || freezeEdit}
            style={{ color: "#EF4444" }}
          >
            Archived Patients
          </Button>
        </div>
      </div>
      {isLoading ? (
        <Skeleton active={isLoading} />
      ) : (
        <Form form={form} component={false}>
          <Table
            
            className="tw-mt-4"
            columns={mergedColumns}
            dataSource={finalResult}
            size="middle"
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            scroll={{ x: 2000 }}
          />
        </Form>
      )}
      {isArchivedModalOpen && (
        <Modal
          open={isArchivedModalOpen}
          width={1000}
          loading={archiveLoading}
          title={"Archived Patients"}
          onCancel={() => setIsArchivedModalOpen(false)}
          footer={null}
        >
          <Table
            columns={archivedColumns}
            dataSource={archivedData}
            size="middle"
          />
        </Modal>
      )}
    </>
  );
};

export default CaseManagementDashboard;
