import React, { useState } from "react";
import { fetcher } from "../../services/fectcher";
import useSWR from "swr";
import { Select, Table, Radio, Space, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { ReloadOutlined } from "@ant-design/icons";
import {
  formatOptionLabel,
  getLobColors,
} from "../../common/elements/myCustomSelect";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import PageTitle from "../Common/PageTitle";
import Search from "antd/es/input/Search";
import { useNewPt360, MODE } from "../../GlobalConfiguration";

const PatientStatus = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Deceased",
    label: "Deceased",
  },
  {
    value: "Dealigned",
    label: "Dealigned",
  },
];
export const lobList = [
  {
    value: "All",
    label: "ALL",
  },
  {
    value: "HN",
    label: "HN",
  },
  {
    value: "PAC",
    label: "PAC",
  },
  {
    value: "SCP",
    label: "SCP",
  },
];

export const CCMDPatientsAll = () => {
  const [status, setStatus] = useState("Active");
  const [lob, setLob] = useState("HN");
  const { data: allPatients, isLoading } = useSWR(
    `/GetAllPatientsList?lob=${lob}&patientStatus=${status}`,
    fetcher
  );

  //  const [editRowIndex, setEditRowIndex] = useState(null);

  // const { data: npPa } = useSWR("GetNpPaList", fetcherhn);
  // const [npList, setNpList] = useState();

  // useEffect(() => {
  //   let t = [];
  //   npPa?.forEach((i) => {
  //     return t.push({
  //       label: i.label,
  //       key: i.value,
  //     });
  //   });
  //   setNpList(t);
  // }, [npPa]);

  const [query, setQuery] = useState("");

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientNameWithDOB",
      fixed: "left",
      width: 240,
      ellipsis: true,
      render: (_, { mbiid, patientNameWithDOB }) => {
        // if (useNewPt360 === true && MODE !== "PRODUCTION") {
        return (
          <>
            {patientNameWithDOB.split(" - ")[2] === "HN" ? (
              <Link to={`/Pt360/${mbiid}`}>
                {formatOptionLabel({ label: patientNameWithDOB })}
              </Link>
            ) : (
              formatOptionLabel({ label: patientNameWithDOB })
            )}
          </>
        );
      },
      // return (
      //   <>
      //     {patientNameWithDOB.split(" - ")[2] === "HN" ? (
      //       <Link to={`/CCMDPatient360/${mbiid}`}>
      //         {formatOptionLabel({ label: patientNameWithDOB })}
      //       </Link>
      //     ) : (
      //       formatOptionLabel({ label: patientNameWithDOB })
      //     )}
      //   </>
      // );
      //  },
    },
    {
      title: "MBI",
      dataIndex: "mbiid",
      width: 100,
    },
    {
      title: "Facility",
      dataIndex: "facilityName",
      width: 150,
      sorter: (a, b) => {
        if (a.facilityName < b.facilityName) {
          return -1;
        }
        if (a.facilityName > b.facilityName) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: "Region",
      dataIndex: "region",
      width: 100,
      sorter: (a, b) => {
        const regionA = a.region || ""; // Replace null with an empty string for comparison
        const regionB = b.region || ""; // Replace null with an empty string for comparison

        return regionA.localeCompare(regionB);
      },
    },

    {
      title: "CCMD Provider",
      dataIndex: "ccmdProvider",
      width: 120,
      sorter: (a, b) => {
        const assignedProviderA = a.ccmdProvider || ""; // Replace null with an empty string for comparison
        const assignedProviderB = b.ccmdProvider || ""; // Replace null with an empty string for comparison
        return assignedProviderA.localeCompare(assignedProviderB);
      },
    },
    {
      title: "Provider Group",
      dataIndex: "providerGroup",
      width: 120,
      // render: (_, { providerGroup }, index) => (
      //   <>
      //     <div className=" tw-flex tw-space-x-3 tw-items-baseline">
      //       {editRowIndex === index ? (
      //         <>
      //           <Select
      //             key={index}
      //             className="tw-flex-grow"
      //             size="small"
      //             options={npList}
      //             autoFocus
      //             onChange={(option) => {
      //               console.log("option", option);
      //               // Perform additional actions such as saving the selection
      //               setEditRowIndex(null);
      //             }}
      //             onBlur={() => setEditRowIndex(null)} // Close the select on blur
      //           />
      //         </>
      //       ) : (
      //         <>
      //           <div>{providerGroup}</div>
      //           <Typography.Link onClick={() => setEditRowIndex(index)}>
      //             Edit
      //           </Typography.Link>
      //         </>
      //       )}
      //     </div>
      //   </>
      // ),
    },

    {
      title: "CMS Aligned Provider",
      dataIndex: "renderingProvider",
      width: 120,

      sorter: (a, b) => {
        const alignedProviderA = a.renderingProvider || ""; // Replace null with an empty string for comparison
        const alignedProviderB = b.renderingProvider || ""; // Replace null with an empty string for comparison
        return alignedProviderA.localeCompare(alignedProviderB);
      },
    },

    {
      title: "Patient Status",
      dataIndex: "patientStatus",
      width: 100,
    },
  ];

  const filteredItems = allPatients
    ? allPatients?.filter((item) => {
        return [
          "patientNameWithDOB",
          "mbiid",
          "facilityName",
          "region",
          "ccmdProvider",
          "providerGroup",
          "renderingProvider",
          "patientStatus",
        ].some((key) =>
          (item[key]?.toString()?.toLowerCase() ?? "").includes(
            query?.toLowerCase() ?? ""
          )
        );
      })
    : allPatients;

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <div className="tw-flex tw-justify-items-center ">
          <Space>
            <PageTitle title={"CCMD Patients"} />
            {isLoading && <MyBackdropSpinner show={true} />}
            <Link to="/cprefresh">
              <Tooltip title="Refresh Patient data from Chartpath">
                <ReloadOutlined className="tw-pb-3" />
              </Tooltip>
            </Link>

            <Radio.Group
              onChange={(e) => setLob(e.target.value)}
              value={lob}
              className="tw-mb-2 tw-ml-2"
            >
              {lobList.map((i) => (
                <Radio key={i.value} value={i.value}>
                  <span
                    className={`tw-py-0.5 tw-px-2 tw-text-xs tw-rounded ${getLobColors(
                      i.value
                    )}`}
                  >
                    {i.label}
                  </span>
                </Radio>
              ))}
            </Radio.Group>
          </Space>
        </div>

        <div className="d-grid gap-2 mb-2 d-md-flex justify-content-end ">
          <Search
            className="col-md-7"
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />

          <Select
            className="col-md-5"
            showSearch
            placeholder="Active"
            optionFilterProp="children"
            defaultValue={PatientStatus[1].value}
            onChange={(e) => setStatus(e)}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={PatientStatus}
          />
        </div>
      </div>
      <Table
        scroll={{ x: 1800 }}
        columns={columns}
        dataSource={filteredItems}
        size="middle"
        pagination={{
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
    </div>
  );
};
