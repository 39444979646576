import React, { useEffect, useState } from "react";
import { AdmissionForm } from "./AdmissionForm";
import * as TocApi from "../../api/ToCareApi";
import { openNotification } from "../../common/elements/myNotification";
import dayjs from "dayjs";

const IPEdit = ({ editRecord, callback, son, ddo }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );

  const sourceOfNotificationOptions = son;
  const dischargeDispositionOptions = ddo;

  const [selectedSon, setSelectedSon] = useState([]);
  const [dischargeDisposition, setDischargeDisposition] = useState();

  const [pageError, setPageError] = useState({});

  useEffect(() => {
    if (editRecord.guid) {
      let sonTemp = [];
      if (editRecord?.notificationSource) {
        sonTemp = sourceOfNotificationOptions?.filter((item) => {
          return item.value === editRecord?.notificationSource;
        });
        setSelectedSon(sonTemp[0]);
      }

      let ddTemp = [];
      if (editRecord?.dischargeDisposition) {
        ddTemp = dischargeDispositionOptions?.filter((item) => {
          return item.value === editRecord?.dischargeDisposition;
        });
      }
      setDischargeDisposition(ddTemp[0]);
    }
  }, [editRecord]);

  const onAdmitDate = (date, dateString) => {
    setFormData({
      ...formData,
      admitDate: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      admitDate: dateString ? "" : "Required",
    });
  };

  const onDeceasedDate = (date, dateString) => {
    setFormData({
      ...formData,
      deceasedDate: dateString ? dateString : "",
    });
    const deceasedDate = dateString ? dayjs(dateString) : null;
    const admitDate = formData.admitDate ? dayjs(formData.admitDate) : null;

    if (admitDate && deceasedDate && deceasedDate.isBefore(admitDate)) {
      setPageError({
        ...pageError,
        deceasedDate: "Deceased date cannot be before admit date",
      });
    } else {
      setPageError({
        ...pageError,
        deceasedDate: "",
      });
    }
  };

  const onDischargeDate = (date, dateString) => {
    setFormData({
      ...formData,
      dischargeDate: dateString ? dateString : "",
    });
    const dischargeDate = dateString ? dayjs(dateString) : null;
    const admitDate = formData.admitDate ? dayjs(formData.admitDate) : null;

    if (admitDate && dischargeDate && dischargeDate.isBefore(admitDate)) {
      setPageError({
        ...pageError,
        dischargeDate: "Discharge date cannot be before admit date",
      });
    } else {
      setPageError({
        ...pageError,
        dischargeDate: "",
      });
    }
  };

  const onSonChange = (e) => {
    setSelectedSon(e);
    setFormData({ ...formData, notificationSource: e ? e.value : "" });
    setPageError({
      ...pageError,
      notificationSource: e ? "" : "Required",
    });
    if (e?.value !== "Other" || e?.value !== "HIE_-_Other") {
      setFormData({
        ...formData,
        notificationSource: e ? e.value : "",
        notificationSourceOtherNotes: "",
      });
    }
  };

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onDischargeDispotionChange = (e) => {
    setDischargeDisposition(e);
    setFormData({ ...formData, dischargeDisposition: e ? e.value : "" });
    setPageError({
      ...pageError,
      dischargeDisposition: e ? "" : "Required",
    });
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        dischargeDisposition: e ? e.value : "",
        dischargeDispositionOtherNotes: "",
      });
    }
  };

  const onSubmit = (e) => {
    let pageErrorTemp = {};
    if (
      !formData?.notificationSource ||
      ((formData?.notificationSource === "Other" ||
        formData?.notificationSource === "HIE_-_Other") &&
        !formData?.notificationSourceOtherNotes) ||
      !formData?.hospitalName ||
      !formData?.reasonAdmittingDx ||
      !formData?.admitDate ||
      !formData.admissionPlanned ||
      (formData?.dischargeDisposition === "Other" &&
        !formData?.dischargeDispositionOtherNotes)
    ) {
      pageErrorTemp.notificationSource = formData?.notificationSource
        ? ""
        : "Required";
      pageErrorTemp.hospitalName = formData?.hospitalName ? "" : "Required";
      pageErrorTemp.reasonAdmittingDx = formData?.reasonAdmittingDx
        ? ""
        : "Required";
      pageErrorTemp.admissionPlanned = formData?.admissionPlanned
        ? ""
        : "Required";
      pageErrorTemp.admitDate = formData?.admitDate ? "" : "Required";
      if (
        formData?.notificationSource === "Other" ||
        formData?.notificationSource === "HIE_-_Other"
      ) {
        pageErrorTemp.notificationSourceOtherNotes =
          formData?.notificationSourceOtherNotes ? "" : "Required";
      }
      if (formData?.dischargeDisposition === "Other") {
        pageErrorTemp.dischargeDispositionOtherNotes =
          formData?.dischargeDispositionOtherNotes ? "" : "Required";
      }
    }

    const deceasedDate = formData.deceasedDate
      ? dayjs(formData.deceasedDate)
      : null;
    const admitDate = formData.admitDate ? dayjs(formData.admitDate) : null;
    if (admitDate && deceasedDate && deceasedDate.isBefore(admitDate)) {
      pageErrorTemp.deceasedDate = "Deceased date cannot be before admit date";
    }

    const dischargeDate = formData.dischargeDate
      ? dayjs(formData.dischargeDate)
      : null;
    if (admitDate && dischargeDate && dischargeDate.isBefore(admitDate)) {
      pageErrorTemp.dischargeDate =
        "Discharge date cannot be before admit date";
    }
    setPageError(pageErrorTemp);

    if (Object.keys(pageErrorTemp).length === 0) {
      onSave(e);
    }
  };

  console.log("errors", pageError);

  const onSave = async () => {
    callback(true);
    try {
      const response = await TocApi.postIPAdmit(formData);
      if (!editRecord?.guid) {
        setFormData([]);
        if (response?.data?.mbi === formData?.mbi) {
          openNotification(
            "error",
            "Error",
            `There is a current IP Admit without a Discharge or Deceased Date. You cannot create a new record without closing the current one.`
          );
        } else if (response?.status === 200) {
          openNotification(
            "success",
            "Added",
            "IP Admission Added Successfully!"
          );
        } else {
          openNotification("error", "Error", "IP Admission failed to Add.");
        }
      } else {
        openNotification(
          "success",
          "Updated",
          "IP Admission Updated Successfully!"
        );
      }
      callback(response);
    } catch (e) {
      console.log("e", e);
    }
  };

  console.log("formData", formData);

  return (
    <div className="tw-relative">
      <div>
        <AdmissionForm
          form="IP"
          sourceOfNotificationOptions={sourceOfNotificationOptions}
          dischargeDispositionOptions={dischargeDispositionOptions}
          onSonChange={onSonChange}
          selectedSon={selectedSon}
          formData={formData}
          handleInputChange={handleInputChange}
          onAdmitDate={onAdmitDate}
          onDeceasedDate={onDeceasedDate}
          onDischargeDate={onDischargeDate}
          onDischargeDispotionChange={onDischargeDispotionChange}
          selectedDD={dischargeDisposition}
          pageError={pageError}
        />
      </div>
      <div className="tw-form-group tw-fixed tw-top-2 tw-right-10">
        <button className="btn btn-success" onClick={onSubmit}>
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(true)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default IPEdit;
