import { Table } from "antd";
import React, { useEffect, useState } from "react";
import PageTitle from "../Common/PageTitle";
import * as HRRPatientsApi from "../../api/HRRPatientsApi";
import Search from "antd/es/input/Search";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import dayjs from "dayjs";
import { MODE } from "../../GlobalConfiguration";

const PatientStatus = [
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Deceased",
    label: "Deceased",
  },
  {
    value: "Discharged",
    label: "Discharged",
  },
];

const CCMDPatients = (props) => {
  const [hrrPatientstMaster, setHRRPatientstMaster] = useState([]);
  const [hrrPatientsColumns, setHRRPatientsColumns] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const [filter, setFilter] = useState({
    mbiid: "",
    firstName: "",
    lastName: "",
    facilityName: "",
    region: "",
    assignedProvider: "",
    alignedProvider: "",
    status: "",
  });

  useEffect(() => {
    getHRRPatients(filter);
  }, [filter]);

  const getHRRPatients = async (filter) => {
    setSpinner(true);
    try {
      const response = await HRRPatientsApi.getHRRPatients(filter);
      let responseData = response?.data?.patientList;

      //MBI
      const uniqueMBITemp = [
        ...new Set(responseData?.map((item) => item.mbiid)),
      ];

      const uniqueMBIList = [];

      uniqueMBITemp.forEach((element) => {
        uniqueMBIList.push({
          text: element,
          value: element,
        });
      });

      //FirstName
      const uniqueFirstNameTemp = [
        ...new Set(responseData?.map((item) => item.firstName)),
      ];

      const uniqueFirstNameList = [];

      uniqueFirstNameTemp.forEach((element) => {
        uniqueFirstNameList.push({
          text: element,
          value: element,
        });
      });

      //LastName
      const uniqueLastNameTemp = [
        ...new Set(responseData?.map((item) => item.lastName)),
      ];

      const uniqueLastNameList = [];

      uniqueLastNameTemp.forEach((element) => {
        uniqueLastNameList.push({
          text: element,
          value: element,
        });
      });

      //Facility Name
      const uniqueFacilityNameTemp = [
        ...new Set(responseData?.map((item) => item.facilityName)),
      ];

      const uniqueFacilityNameList = [];

      uniqueFacilityNameTemp.forEach((element) => {
        uniqueFacilityNameList.push({
          text: element,
          value: element,
        });
      });

      //Region
      const uniqueRegionTemp = [
        ...new Set(responseData?.map((item) => item.region)),
      ];

      const uniqueRegionList = [];

      uniqueRegionTemp.forEach((element) => {
        uniqueRegionList.push({
          text: element,
          value: element,
        });
      });

      //MDS Assigned Provider
      const uniqueAssignedProviderTemp = [
        ...new Set(responseData?.map((item) => item.assignedProvider)),
      ];

      const uniqueAssignedProviderList = [];

      uniqueAssignedProviderTemp.forEach((element) => {
        uniqueAssignedProviderList.push({
          text: element,
          value: element,
        });
      });

      //CMS Aligned provider
      const uniqueAlignedProviderTemp = [
        ...new Set(responseData?.map((item) => item.alignedProvider)),
      ];

      const uniqueAlignedProviderList = [];

      uniqueAlignedProviderTemp.forEach((element) => {
        uniqueAlignedProviderList.push({
          text: element,
          value: element,
        });
      });

      //Patient Status
      const uniquePatientStatusTemp = [
        ...new Set(responseData?.map((item) => item.status)),
      ];

      const uniquePatientStatusList = [];

      uniquePatientStatusTemp.forEach((element) => {
        uniquePatientStatusList.push({
          text: element,
          value: element,
        });
      });

      setHRRPatientstMaster(responseData);
      let I = responseData?.filter((item) => {
        return ["status"].some((key) =>
          (item[key]?.toString()?.toLowerCase() ?? "").includes(
            PatientStatus[0].value.toLowerCase() ?? ""
          )
        );
      });
      setFilteredData(I);

      const mySort = (a, b, key) => {
        const valueA = a[key] || "";
        const valueB = b[key] || "";
        return valueA.localeCompare(valueB);
      };

      setHRRPatientsColumns([
        {
          title: "Name",
          dataIndex: "lastName",
          fixed: "left",
          width: 110,
          ellipsis: true,
          render: (columnItem, rowItem) => {
            if (MODE !== "PRODUCTION") {
              return (
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/Pt360/${rowItem.mbiid}`);
                    //  navigate(`/CCMDPatient360/${rowItem.mbiid}`);
                  }}
                  href="#"
                >
                  {rowItem.lastName + ", " + rowItem.firstName}
                </a>
              );
            }
            return (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/CCMDPatient360/${rowItem.mbiid}`);
                }}
                href="#"
              >
                {rowItem.lastName + ", " + rowItem.firstName}
              </a>
            );
          },
          sorter: (a, b) => {
            if (a.lastName < b.lastName) {
              return -1;
            }
            if (a.lastName > b.lastName) {
              return 1;
            }
            return 0;
          },
        },

        {
          title: "DOB",
          dataIndex: "dateOfBirth",
          width: 60,
          render: (dateOfBirth) =>
            dayjs(dateOfBirth).utc().format("YYYY-MM-DD"),
        },
        {
          title: "MBI",
          dataIndex: "mbiid",
          fixed: "left",
          width: 70,
        },
        {
          title: "Facility",
          dataIndex: "facilityName",
          width: 150,
          // filters: uniqueFacilityNameList,
          // onFilter: (value, record) =>
          //   record?.facilityName?.indexOf(value) === 0,
          sorter: (a, b) => {
            if (a.facilityName < b.facilityName) {
              return -1;
            }
            if (a.facilityName > b.facilityName) {
              return 1;
            }
            return 0;
          },
        },

        {
          title: "Region",
          dataIndex: "region",
          width: 50,

          sorter: (a, b) => {
            const regionA = a.region || ""; // Replace null with an empty string for comparison
            const regionB = b.region || ""; // Replace null with an empty string for comparison

            // Use the localeCompare method to compare strings in a case-insensitive manner
            return regionA.localeCompare(regionB);
          },
        },

        {
          title: "CCMD Provider",
          dataIndex: "assignedProvider",
          width: 120,
          // filters: uniqueAssignedProviderList,
          // onFilter: (value, record) =>
          //   record?.assignedProvider?.indexOf(value) === 0,
          sorter: (a, b) => {
            const assignedProviderA = a.assignedProvider || ""; // Replace null with an empty string for comparison
            const assignedProviderB = b.assignedProvider || ""; // Replace null with an empty string for comparison
            return assignedProviderA.localeCompare(assignedProviderB);
          },
        },

        {
          title: "CMS Aligned Provider",
          dataIndex: "alignedProvider",
          width: 120,
          // filters: uniqueAlignedProviderList,
          // onFilter: (value, record) =>
          //   record?.alignedProvider?.indexOf(value) === 0,
          sorter: (a, b) => {
            const alignedProviderA = a.alignedProvider || ""; // Replace null with an empty string for comparison
            const alignedProviderB = b.alignedProvider || ""; // Replace null with an empty string for comparison
            return alignedProviderA.localeCompare(alignedProviderB);
          },
        },

        {
          title: "Patient Status",
          dataIndex: "status",
          width: 50,
        },
      ]);
      setSpinner(false);
      return responseData;
    } catch (error) {
      console.error(error);
      setHRRPatientstMaster([]);
    }
  };

  const onChange = (pagination, filters, sorter, extra) => {
    //setFilterItems(filters);
  };

  const onStatusChange = (e) => {
    // setFilter((prevFilter) => ({
    //   ...prevFilter,
    //   status: e,
    // }));
    if (e !== "") {
      let Items = hrrPatientstMaster?.filter((item) => {
        return ["status"].some((key) =>
          (item[key]?.toString()?.toLowerCase() ?? "").includes(
            e?.toLowerCase() ?? ""
          )
        );
      });
      setFilteredData(Items);
    } else {
      setFilteredData(hrrPatientstMaster);
    }
  };

  const filteredItems = filteredData.filter((item) => {
    return Object.keys(item).some((key) =>
      (item[key]?.toString()?.toLowerCase() ?? "").includes(
        query?.toLowerCase() ?? ""
      )
    );
  });

  return (
    <>
      <div className="tw-flex tw-justify-between tw-mb-2">
        <PageTitle title={"CCMD Patients"} />
        {spinner && <MyBackdropSpinner show={spinner} />}

        <div className="d-grid gap-2 my-2 d-md-flex justify-content-end ">
          <Search
            className="col-md-7"
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value)}
            allowClear
          />
          <Select
            className="col-md-5"
            showSearch
            placeholder="Active"
            optionFilterProp="children"
            defaultValue={PatientStatus[0].value}
            onChange={onStatusChange}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={PatientStatus}
          />
        </div>
      </div>

      <div className="row">
        <Table
          columns={hrrPatientsColumns}
          dataSource={filteredItems}
          size="middle"
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
          }}
        />
      </div>
    </>
  );
};

export default CCMDPatients;
