import { useRef, useEffect, useState } from "react";
import PageTitle from "../Common/PageTitle";
import MyTextBox from "../../common/elements/myTextbox";
import { DatePicker, FloatButton, Space } from "antd";
import dayjs from "dayjs";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../services/fectcher";
import { IoArrowBackCircle } from "react-icons/io5";
import TextArea from "antd/es/input/TextArea";
import { usePickList } from "../../services/queries";
import { openNotification } from "../../common/elements/myNotification";
import { SaveRCA } from "../../api/ToCareApi";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";

export default function RootCauseAnalysis() {
  const params = useParams();
  const location = useLocation();

  const initialState = {
    admitGuid: params.guid,
    guid: "",
    mbi: "",
    patientName: "",
    dob: "",
    ccmdAssignedProvider: "",
    facilityorLocation: "",
    bloodSugar: "",
    bloodSugarRangeOutOfPatient: "",
    patientState: "",
    region: "",
    codeStatus: "",
    admissionType: "",
    sourceofNotification: "",
    dateofAdmission: "",
    dichargeDate: "",
    deceasedDate: "",
    dichargeDisposistion: "",
    dischargeDiagnosis: "",
    rcaDateandTime: "",
    emrDataAvailable: "",
    codeStatusDiffers: "",
    attendingProvider: "",
    admittingFacilityorLocation: "",
    admittedFrom: "",
    admittedFromOther: "",
    transportedVia: "",
    transportedViaOther: "",
    admissionCheifComplaint: "",
    planofTreatmentorNotes: "",
    wasAdmissionCheifComplaint: "",
    wasAdmissionCheifComplaintOther: "",
    contactedCCMDpriorAdmission: "",
    contactedCCMDpriorAdmissionDate: "",
    signSymptomsPlansDiss: "",
    changeinConditionPriorAdmission: "",
    ipAdmissionPast30Days: "",
    ipAdmissionPast30DaysDate: "",
    eDInThePast30Days: "",
    eDInThePast30DaysDatec: "",
    changeinConditionPriorAdmissionNotes: "",
    healthIndicators: "",
    healthIndicatorsNotes: "",
    temperature: "",
    bloodPressureSystolicDiastolic: "",
    cardiacMedications: "",
    cardiacMedicationsNotes: "",
    respirations: "",
    pulse: "",
    patientOnO2: "",
    o2EmrNotes: "",
    o2Saturation: "",
    possibleCausesOfAdmission: "",
    possibleCausesOfAdmissionOther: "",
    opportunityToTreatThePatient: "",
    opportunityToTreatThePatientNotes: "",
    potentialCauses: "",
    recommendationsForImprovement: "",
    medicalSummaryNotes: "",
    dateOfLastProvider: "",
    status: "",
    createdBy: "",
    modifiedBy: "",
  };

  const admittedFromOptions = usePickList(27).data;
  const codeStatusOptions = usePickList(28).data;
  const patientMethodOfTansportOptions = usePickList(29).data;
  const ChiefComplaintOptions = usePickList(30).data;
  const CauseOfAdmissionOptions = usePickList(31).data;
  const PotentialCauseOptions = usePickList(32).data;
  const ImprovementOptions = usePickList(33).data;
  const healthIndicatorOptions = usePickList(37).data;

  const navigate = useNavigate();
  const admissionDetailsRef = useRef();
  const vitalsRef = useRef();
  const opportunitiesRef = useRef();

  const { data, isLoading } = useSWR(
    `GetRcaForIPAdmit?ipadmitguid=${params.guid}`,
    fetcher
  );

  const [activeFormSection, setActiveFormSection] =
    useState("admission-details");

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const targetID = entries.filter((entry) => entry.isIntersecting)[0]
          ?.target.id;
        if (targetID) {
          setActiveFormSection(targetID);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.2,
      }
    );
    observer.observe(admissionDetailsRef.current);
    observer.observe(vitalsRef.current);
    observer.observe(opportunitiesRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(
    location.pathname.includes("report") ? true : false
  );

  const [formData, setFormData] = useState(initialState);

  const [admittedFrom, setAdmittedFrom] = useState([]);
  const [codeStat, setCodeStat] = useState([]);
  const [wasTransported, setWasTransported] = useState([]);
  const [wasAdmissionCheifComplaint, setWasAdmissionCheifComplaint] = useState(
    []
  );

  const [posCausesOfAdmission, setPosCausesOfAdmission] = useState([]);
  const [healthIndicators, setHealthIndicators] = useState([]);
  const [potentialCauses, setPotentialCauses] = useState([]);
  const [recommForImprov, setRecommForImprov] = useState([]);

  const handleSelectChange = (fieldName, e) => {
    setFormData({
      ...formData,
      [fieldName]: e ? e.value : "",
    });
    setPageError({
      ...pageError,
      [fieldName]: e ? "" : "Required",
    });
  };

  const handleMultiSelectChange = (fieldName, e) => {
    setFormData({
      ...formData,
      [fieldName]: e ? e.map((item) => item.value).join(",") : "",
    });
    setPageError({
      ...pageError,
      [fieldName]: e ? "" : "Required",
    });
  };

  const handleDateChange = (fieldName, dateString) => {
    let dateToSet = dateString ? dateString : "";

    if (fieldName === "rcaDateandTime" && !dateString) {
      dateToSet = dayjs().format("YYYY-MM-DD HH:mm");
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: dateToSet,
      ...(fieldName === "patientsLastBmOnRecord" &&
        !dateString && { bowelProtocolsImpl: "" }),
    }));

    setPageError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: dateString ? "" : "Required",
    }));
  };

  const resetDependentFields = (fields) => {
    const resetFields = {};
    fields.forEach((field) => (resetFields[field] = ""));
    return resetFields;
  };

  const handleInputChange = (field, value) => {
    const resetFieldsMap = {
      contactedCCMDpriorAdmission: [
        "contactedCCMDpriorAdmissionDate",
        "signSymptomsPlansDiss",
      ],
      ipAdmissionPast30Days: ["ipAdmissionPast30DaysDate"],
      cardiacMedications: ["cardiacMedicationsNotes"],
      patientOnO2: ["o2EmrNotes"],
      bloodSugarRangeOutOfPatient: ["bloodSugarEmrNotes"],
      changesInIntake: ["changesInIntakeNotes"],
      opportunityToTreatThePatient: ["opportunityToTreatThePatientNotes"],
    };

    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
      ...(value === "No" && resetFieldsMap[field]
        ? resetDependentFields(resetFieldsMap[field])
        : {}),
    }));

    setPageError((prevErrors) => ({
      ...prevErrors,
      [field]: value ? "" : "Required",
    }));
  };

  const onAdmittedFromChange = (e) => {
    setAdmittedFrom(e);
    handleSelectChange("admittedFrom", e);
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        admittedFrom: e ? e.value : "",
        admittedFromOther: "",
      });
    }
  };

  const wasTransportedChange = (e) => {
    setWasTransported(e);
    handleSelectChange("transportedVia", e);
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        transportedVia: e ? e.value : "",
        transportedViaOther: "",
      });
    }
  };
  const wasCodeStatusChange = (e) => {
    setCodeStat(e);
    handleSelectChange("codeStatusDiffers", e);
  };

  const wasAdmissionCheifComplaintChange = (e) => {
    setWasAdmissionCheifComplaint(e);
    handleSelectChange("wasAdmissionCheifComplaint", e);
    if (e?.value !== "Other") {
      setFormData({
        ...formData,
        wasAdmissionCheifComplaint: e ? e.value : "",
        wasAdmissionCheifComplaintOther: "",
      });
    }
  };

  const onposCausesOfAdmissionChange = (e) => {
    setPosCausesOfAdmission(e);
    handleMultiSelectChange("possibleCausesOfAdmission", e);
    if (e && !e.some((item) => item.value === "Other")) {
      handleInputChange("possibleCausesOfAdmissionOther", "");
    }
  };

  const onHealthIndicatorsChange = (e) => {
    setHealthIndicators(e);
    handleMultiSelectChange("healthIndicators", e);
    if (e.length === 0) {
      handleInputChange("healthIndicatorsNotes", "");
    }
  };

  const PotentialChancesChange = (e) => {
    setPotentialCauses(e);
    handleMultiSelectChange("potentialCauses", e);
  };

  const RecommforImprovChange = (e) => {
    setRecommForImprov(e);
    handleMultiSelectChange("recommendationsForImprovement", e);
  };

  useEffect(() => {
    if (data) {
      const updatedFormData = { ...formData };

      for (const key in data) {
        if (data.hasOwnProperty(key) && data[key] !== null) {
          updatedFormData[key] = data[key];
        }
      }

      updatedFormData["modifiedBy"] = sessionStorage.getItem("userName");

      if (!updatedFormData["rcaDateandTime"]) {
        updatedFormData["rcaDateandTime"] = dayjs().format("YYYY-MM-DD HH:mm");
      }

      const defaultDate = "1900-01-01";

      const fieldsToCheck = [
        "contactedCCMDpriorAdmissionDate",
        "ipAdmissionPast30DaysDate",
        "dateOfLastProvider",
        "patientsLastBmOnRecord",
        "eDInThePast30DaysDatec",
      ];

      fieldsToCheck.forEach((field) => {
        if (updatedFormData[field] === defaultDate) {
          updatedFormData[field] = "";
        }
      });

      const defaultValues = {
        emrDataAvailable: "No",
        contactedCCMDpriorAdmission: "No",
        changeinConditionPriorAdmission: "No",
        ipAdmissionPast30Days: "No",
        eDInThePast30Days: "No",
        cardiacMedications: "No",
        patientOnO2: "No",
        bloodSugarRangeOutOfPatient: "No",
        opportunityToTreatThePatient: "No",
      };

      for (const key in defaultValues) {
        if (!updatedFormData[key]) {
          updatedFormData[key] = defaultValues[key];
        }
      }

      const filterAndSet = (dataField, options, setter) => {
        if (data[dataField]) {
          const filteredData = options?.filter(
            (item) => item.value === data[dataField]
          );
          setter(filteredData);
        } else {
          setter([]);
        }
      };

      filterAndSet("codeStatusDiffers", codeStatusOptions, setCodeStat);
      filterAndSet("admittedFrom", admittedFromOptions, setAdmittedFrom);
      filterAndSet(
        "transportedVia",
        patientMethodOfTansportOptions,
        setWasTransported
      );
      filterAndSet(
        "wasAdmissionCheifComplaint",
        ChiefComplaintOptions,
        setWasAdmissionCheifComplaint
      );

      const filterAndSetMultiple = (dataField, options, setter) => {
        if (data[dataField]) {
          const array = data[dataField].split(",");
          const filteredData = array.flatMap((e) =>
            options?.filter((option) => option.value === e)
          );
          setter(filteredData);
        } else {
          setter([]);
        }
      };

      filterAndSetMultiple(
        "possibleCausesOfAdmission",
        CauseOfAdmissionOptions,
        setPosCausesOfAdmission
      );
      filterAndSetMultiple(
        "potentialCauses",
        PotentialCauseOptions,
        setPotentialCauses
      );
      filterAndSetMultiple(
        "recommendationsForImprovement",
        ImprovementOptions,
        setRecommForImprov
      );
      filterAndSetMultiple(
        "healthIndicators",
        healthIndicatorOptions,
        setHealthIndicators
      );

      setFormData(updatedFormData);
    }
  }, [data]);

  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (
      !formData?.codeStatusDiffers ||
      !formData?.admittedFrom ||
      (formData?.admittedFrom === "Other" && !formData?.admittedFromOther) ||
      !formData?.wasAdmissionCheifComplaint ||
      (formData?.ipAdmissionPast30Days === "Yes" &&
        !formData?.ipAdmissionPast30DaysDate) ||
      (formData?.eDInThePast30Days === "Yes" &&
        !formData?.eDInThePast30DaysDatec) ||
      (formData?.contactedCCMDpriorAdmission === "Yes" &&
        !formData?.contactedCCMDpriorAdmissionDate) ||
      (formData?.wasAdmissionCheifComplaint === "Other" &&
        !formData?.wasAdmissionCheifComplaintOther)
    ) {
      pageErrorTemp.codeStatusDiffers = formData?.codeStatusDiffers
        ? ""
        : "Required";
      pageErrorTemp.admittedFrom = formData?.admittedFrom ? "" : "Required";
      if (formData?.admittedFrom === "Other") {
        pageErrorTemp.admittedFromOther = formData?.admittedFromOther
          ? ""
          : "Required";
      }
      pageErrorTemp.wasAdmissionCheifComplaint =
        formData?.wasAdmissionCheifComplaint ? "" : "Required";
      if (formData?.ipAdmissionPast30Days === "Yes") {
        pageErrorTemp.ipAdmissionPast30DaysDate =
          formData?.ipAdmissionPast30DaysDate ? "" : "Required";
      }
      if (formData?.eDInThePast30Days === "Yes") {
        pageErrorTemp.eDInThePast30DaysDatec = formData?.eDInThePast30DaysDatec
          ? ""
          : "Required";
      }
      if (formData?.contactedCCMDpriorAdmission === "Yes") {
        pageErrorTemp.contactedCCMDpriorAdmissionDate =
          formData?.contactedCCMDpriorAdmissionDate ? "" : "Required";
      }
      if (formData?.wasAdmissionCheifComplaint === "Other") {
        pageErrorTemp.wasAdmissionCheifComplaintOther =
          formData?.wasAdmissionCheifComplaintOther ? "" : "Required";
      }
      setPageError(pageErrorTemp);
      return;
    }

    if (!formData?.rcaDateandTime) {
      const currentDateTime = dayjs().format("YYYY-MM-DD HH:mm");
      setFormData((prevFormData) => ({
        ...prevFormData,
        rcaDateandTime: currentDateTime,
      }));
    }

    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      const response = await SaveRCA(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Root Cause Analysis Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Root Cause Analysis Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "Root Cause Analysis Failed to Add!"
        );
      }

      setDisable(false);
    } catch (e) {
      return e;
    }
  };

  return (
    <>
      {isLoading && <MyBackdropSpinner show={isLoading} />}
      <div className="tw-flex tw-space-x-12 tw-mt-10">
        <div className="tw-w-1/4 tw-h-min tw-sticky tw-top-14">
          <Space align="start">
            <IoArrowBackCircle
              size="2em"
              onClick={() =>
                disable
                  ? navigate("/reports/rcareports", {
                      state: { lob: location.state.lob },
                    })
                  : navigate("/care/tocview", {
                      state: {
                        patient: location?.state?.patient,
                        type: location?.state?.type,
                      },
                    })
              }
            />

            <PageTitle title="Root Cause Analysis" />
          </Space>
          <div className=" tw-space-y-2 tw-h-min tw-sticky tw-top-14 tw-pl-10">
            <div
              className={`tw-cursor-pointer tw-underline ${
                activeFormSection === "admission-details"
                  ? "tw-decoration-blue-400 tw-text-blue-800"
                  : "tw-decoration-gray-300 tw-text-gray-700"
              }`}
              onClick={() => admissionDetailsRef.current.scrollIntoView()}
            >
              Admission, Treatment and Discharge Details
            </div>
            <div
              className={`tw-cursor-pointer tw-underline ${
                activeFormSection === "vitals"
                  ? "tw-decoration-blue-400 tw-text-blue-800"
                  : "tw-decoration-gray-300 tw-text-gray-700"
              }`}
              onClick={() => vitalsRef.current.scrollIntoView()}
            >
              Vitals / Health Indicators
            </div>

            <div
              className={`tw-cursor-pointer tw-underline ${
                activeFormSection === "opportunities"
                  ? "tw-decoration-blue-400 tw-text-blue-800"
                  : "tw-decoration-gray-300 tw-text-gray-700"
              }`}
              onClick={() => opportunitiesRef.current.scrollIntoView()}
            >
              Improvement / Opportunities
            </div>
            <div className="form-group">
              <button
                className={
                  !disable ? "btn btn-success" : "btn btn-success tw-hidden"
                }
                onClick={onSubmit}
              >
                Save
              </button>
            </div>
          </div>
        </div>

        <div
          className={
            disable
              ? "tw-w-3/4 tw-mt-4 tw-pointer-events-none "
              : "tw-w-3/4 tw-mt-4"
          }
        >
          <div className="tw-bg-gray-100 -tw-m-4 tw-p-8 tw-rounded-md tw-mr-36">
            <div className="tw-capitalize tw-text-3xl tw-text-gray-800">
              {formData?.patientName}
            </div>
            <div className="tw-grid tw-grid-cols-2 tw-gap-x-8 tw-mt-4">
              <div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">
                    Date of Birth
                  </div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.dob || "NA"}
                  </div>
                </div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">
                    CCMD Assigned Provider
                  </div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.ccmdAssignedProvider || "NA"}
                  </div>
                </div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">
                    Facility / Location
                  </div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.facilityorLocation || "NA"}
                  </div>
                </div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">State</div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.patientState || "NA"}
                  </div>
                </div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">Region</div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.region || "NA"}
                  </div>
                </div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">
                    Admission Type
                  </div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.admissionType || "NA"}
                  </div>
                </div>
              </div>
              <div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">
                    Source of Notification
                  </div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.sourceofNotification
                      ? formData.sourceofNotification.replace(/_/g, " ")
                      : "NA"}
                  </div>
                </div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">
                    Date of Admission
                  </div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.dateofAdmission || "NA"}
                  </div>
                </div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">
                    Date of Discharge
                  </div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.dichargeDate || "NA"}
                  </div>
                </div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">
                    Date of Death
                  </div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.deceasedDate || "NA"}
                  </div>
                </div>
                <div className="tw-flex tw-justify-between">
                  <div className="tw-text-gray-500 tw-shrink-0">
                    Discharge Disposition
                  </div>
                  <div className="tw-font-medium tw-text-right">
                    {formData?.dichargeDisposistion
                      ? formData.dichargeDisposistion.replace(/_/g, " ")
                      : "NA"}
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-flex tw-flex-col tw-mt-4">
              <div className="tw-text-gray-500 tw-shrink-0">
                Discharge Diagnosis
              </div>
              <div className="tw-font-medium  tw-text-left">
                {formData?.dischargeDiagnosis || "NA"}
              </div>
            </div>
          </div>

          <div
            id="admission-details"
            className="tw-w-2/3"
            ref={admissionDetailsRef}
          >
            <h3 className="tw-mt-14">
              Admission, Treatment and Discharge Details
            </h3>
            <div className="tw-space-y-5">
              <div className="form-group">
                <label className="tw-block tw-font-bold">RCA Date</label>
                <DatePicker
                  className="tw-w-48"
                  placeholder="YYYY-MM-DD hh:mm"
                  format={"YYYY-MM-DD hh:mm A"}
                  showTime
                  value={
                    formData?.rcaDateandTime
                      ? dayjs(formData?.rcaDateandTime)
                      : dayjs()
                  }
                  onChange={(date, dateString) =>
                    handleDateChange("rcaDateandTime", dateString)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="selectedOption" className="fw-bold">
                  Patient's Chief Complaint / Admitting Dx
                </label>
                <MyCustomSelect
                  selectOptions={ChiefComplaintOptions}
                  selectedValue={wasAdmissionCheifComplaint}
                  myCallBack={wasAdmissionCheifComplaintChange}
                  errorMessage={pageError?.wasAdmissionCheifComplaint}
                />
                {formData?.wasAdmissionCheifComplaint === "Other" && (
                  <div className="form-group tw-mt-4">
                    <label className="fw-bold tw-mb-1">Other</label>
                    <TextArea
                      className="form-control"
                      maxLength={"1000"}
                      value={formData?.wasAdmissionCheifComplaintOther}
                      onChange={(e) =>
                        handleInputChange(
                          "wasAdmissionCheifComplaintOther",
                          e.target.value
                        )
                      }
                      style={{
                        border: pageError?.wasAdmissionCheifComplaintOther
                          ? "1px solid red"
                          : "",
                      }}
                      errorMessage={pageError?.wasAdmissionCheifComplaintOther}
                    />
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="tw-block tw-font-bold">
                  Is there available EMR data?
                </label>
                <fieldset className="fw-normal tw-mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="EMRyes"
                      checked={formData?.emrDataAvailable === "Yes"}
                      id="EMRYes"
                      value={"Yes"}
                      onChange={(e) =>
                        handleInputChange(
                          "emrDataAvailable",
                          e.currentTarget.value ? "Yes" : "No"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="EMRNo"
                      checked={formData?.emrDataAvailable === "No"}
                      id="EMRNo"
                      value={"No"}
                      onChange={(e) =>
                        handleInputChange(
                          "emrDataAvailable",
                          e.currentTarget.value ? "No" : "Yes"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsNo"
                    >
                      No
                    </label>
                  </div>
                </fieldset>
              </div>
              <div className="form-group">
                <label htmlFor="selectedOption" className="fw-bold">
                  Hospital's Plan of Treatment or Notes
                </label>
                <TextArea
                  className="form-control"
                  maxLength={"1000"}
                  value={formData?.planofTreatmentorNotes}
                  onChange={(e) =>
                    handleInputChange("planofTreatmentorNotes", e.target.value)
                  }
                />
              </div>

              <div className="form-group">
                <label className="tw-block tw-font-bold">Code Status</label>
                <MyCustomSelect
                  selectOptions={codeStatusOptions}
                  selectedValue={codeStat}
                  myCallBack={wasCodeStatusChange}
                  errorMessage={pageError?.codeStatusDiffers}
                />
              </div>
              <div className="form-group">
                <MyTextBox
                  label={"Attending Provider"}
                  value={formData?.attendingProvider}
                  maxLength={100}
                  setValue={(e) =>
                    handleInputChange("attendingProvider", e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <MyTextBox
                  label={"Admitting Facility Name / Location"}
                  value={formData?.admittingFacilityorLocation}
                  maxLength={100}
                  setValue={(e) =>
                    handleInputChange(
                      "admittingFacilityorLocation",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="selectedOption" className="fw-bold">
                  Patient was admitted from
                </label>
                <MyCustomSelect
                  selectOptions={admittedFromOptions}
                  selectedValue={admittedFrom}
                  myCallBack={onAdmittedFromChange}
                  errorMessage={pageError?.admittedFrom}
                />
                {formData?.admittedFrom === "Other" && (
                  <div className="form-group">
                    <MyTextBox
                      className="form-control"
                      maxLength={"50"}
                      value={formData?.admittedFromOther}
                      setValue={(e) =>
                        handleInputChange("admittedFromOther", e.target.value)
                      }
                      errorMessage={pageError?.admittedFromOther}
                    />
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="selectedOption" className="fw-bold">
                  Patient's method of Transportation
                </label>
                <MyCustomSelect
                  selectOptions={patientMethodOfTansportOptions}
                  selectedValue={wasTransported}
                  myCallBack={wasTransportedChange}
                />
                {formData?.transportedVia === "Other" && (
                  <div className="form-group">
                    <MyTextBox
                      className="form-control"
                      maxLength={"50"}
                      value={formData?.transportedViaOther}
                      setValue={(e) =>
                        handleInputChange("transportedViaOther", e.target.value)
                      }
                    />
                  </div>
                )}
              </div>

              <div className="form-group">
                <label className="tw-block tw-font-bold">
                  Did the patient/facility contact CCMD prior to admission?
                </label>
                <fieldset className="fw-normal tw-mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="contactedCCMDpriorAdmissionYes"
                      checked={formData?.contactedCCMDpriorAdmission === "Yes"}
                      id="contactedCCMDpriorAdmissionYes"
                      value={"Yes"}
                      onChange={(e) =>
                        handleInputChange(
                          "contactedCCMDpriorAdmission",
                          e.currentTarget.value ? "Yes" : "No"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="contactedCCMDpriorAdmissionNo"
                      checked={formData?.contactedCCMDpriorAdmission === "No"}
                      id="contactedCCMDpriorAdmissionNo"
                      value={"No"}
                      onChange={(e) =>
                        handleInputChange(
                          "contactedCCMDpriorAdmission",
                          e.currentTarget.value ? "No" : "Yes"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsNo"
                    >
                      No
                    </label>
                  </div>
                  {formData?.contactedCCMDpriorAdmission === "Yes" && (
                    <div className="form-group tw-mt-2">
                      <label className="tw-block tw-font-bold">
                        Contact Date
                      </label>
                      <DatePicker
                        className="tw-w-48"
                        placeholder="YYYY-MM-DD"
                        format={"YYYY-MM-DD"}
                        value={
                          formData?.contactedCCMDpriorAdmissionDate
                            ? dayjs(formData?.contactedCCMDpriorAdmissionDate)
                            : ""
                        }
                        style={{
                          border: pageError?.contactedCCMDpriorAdmissionDate
                            ? "1px solid red"
                            : "",
                        }}
                        onChange={(date, dateString) =>
                          handleDateChange(
                            "contactedCCMDpriorAdmissionDate",
                            dateString
                          )
                        }
                      />
                      <div className="form-group tw-mt-2">
                        <label
                          htmlFor="symptoms"
                          className="tw-block tw-font-bold"
                        >
                          What signs, symptoms or plans were discussed?
                        </label>
                        <TextArea
                          className="form-control"
                          maxLength={"1000"}
                          value={formData?.signSymptomsPlansDiss}
                          onChange={(e) =>
                            handleInputChange(
                              "signSymptomsPlansDiss",
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>
                  )}
                </fieldset>
              </div>

              <div className="form-group">
                <label className="tw-block tw-font-bold">
                  Was Provider notified of change in condition prior to
                  admission?
                </label>
                <fieldset className="fw-normal tw-mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="changeinConditionPriorAdmissionYes"
                      checked={
                        formData?.changeinConditionPriorAdmission === "Yes"
                      }
                      id="changeinConditionPriorAdmissionYes"
                      value={"Yes"}
                      onChange={(e) =>
                        handleInputChange(
                          "changeinConditionPriorAdmission",
                          e.currentTarget.value ? "Yes" : "No"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="changeinConditionPriorAdmissionNo"
                      checked={
                        formData?.changeinConditionPriorAdmission === "No"
                      }
                      id="changeinConditionPriorAdmissionNo"
                      value={"No"}
                      onChange={(e) =>
                        handleInputChange(
                          "changeinConditionPriorAdmission",
                          e.currentTarget.value ? "No" : "Yes"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsNo"
                    >
                      No
                    </label>
                  </div>
                  {formData?.changeinConditionPriorAdmission === "Yes" && (
                    <div className="form-group tw-mt-4">
                      <label className="fw-bold tw-mb-1">
                        Describe the Communication
                      </label>
                      <TextArea
                        className="form-control"
                        maxLength={"1000"}
                        value={formData?.changeinConditionPriorAdmissionNotes}
                        onChange={(e) =>
                          handleInputChange(
                            "changeinConditionPriorAdmissionNotes",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )}
                </fieldset>
              </div>
              <div className="form-group">
                <label className="tw-block tw-font-bold">
                  Has the patient had an IP Admission in the past 30 days?
                </label>
                <fieldset className="fw-normal tw-mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="ipAdmissionPast30DaysYes"
                      checked={formData?.ipAdmissionPast30Days === "Yes"}
                      id="ipAdmissionPast30DaysYes"
                      value={"Yes"}
                      onChange={(e) =>
                        handleInputChange(
                          "ipAdmissionPast30Days",
                          e.currentTarget.value ? "Yes" : "No"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="ipAdmissionPast30DaysNo"
                      checked={formData?.ipAdmissionPast30Days === "No"}
                      id="ipAdmissionPast30DaysNo"
                      value={"No"}
                      onChange={(e) =>
                        handleInputChange(
                          "ipAdmissionPast30Days",
                          e.currentTarget.value ? "No" : "Yes"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsNo"
                    >
                      No
                    </label>
                  </div>
                  {formData?.ipAdmissionPast30Days === "Yes" && (
                    <div className="form-group tw-mt-2">
                      <label className="tw-block tw-font-bold">
                        IP Admission Date
                      </label>
                      <DatePicker
                        className="tw-w-48"
                        placeholder="YYYY-MM-DD"
                        format={"YYYY-MM-DD"}
                        value={
                          formData?.ipAdmissionPast30DaysDate
                            ? dayjs(formData?.ipAdmissionPast30DaysDate)
                            : ""
                        }
                        onChange={(date, dateString) =>
                          handleDateChange(
                            "ipAdmissionPast30DaysDate",
                            dateString
                          )
                        }
                        style={{
                          border: pageError?.ipAdmissionPast30DaysDate
                            ? "1px solid red"
                            : "",
                        }}
                      />
                    </div>
                  )}
                </fieldset>
              </div>
              <div className="form-group">
                <label className="tw-block tw-font-bold">
                  Has the patient been to ED in the past 30 days?
                </label>
                <fieldset className="fw-normal tw-mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="eDInThePast30DaysYes"
                      checked={formData?.eDInThePast30Days === "Yes"}
                      id="eDInThePast30DaysYes"
                      value={"Yes"}
                      onChange={(e) =>
                        handleInputChange(
                          "eDInThePast30Days",
                          e.currentTarget.value ? "Yes" : "No"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="eDInThePast30DaysNo"
                      checked={formData?.eDInThePast30Days === "No"}
                      id="eDInThePast30DaysNo"
                      value={"No"}
                      onChange={(e) =>
                        handleInputChange(
                          "eDInThePast30Days",
                          e.currentTarget.value ? "No" : "Yes"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsNo"
                    >
                      No
                    </label>
                  </div>
                  {formData?.eDInThePast30Days === "Yes" && (
                    <div className="form-group tw-mt-2">
                      <label className="tw-block tw-font-bold">
                        ED Visit Date
                      </label>
                      <DatePicker
                        className="tw-w-48"
                        placeholder="YYYY-MM-DD"
                        format={"YYYY-MM-DD"}
                        value={
                          formData?.eDInThePast30DaysDatec
                            ? dayjs(formData?.eDInThePast30DaysDatec)
                            : ""
                        }
                        onChange={(date, dateString) =>
                          handleDateChange("eDInThePast30DaysDatec", dateString)
                        }
                        style={{
                          border: pageError?.eDInThePast30DaysDatec
                            ? "1px solid red"
                            : "",
                        }}
                      />
                    </div>
                  )}
                </fieldset>
              </div>
            </div>
          </div>

          {/* -------------------------Part - One----------------------- */}

          <div id="vitals" className="tw-w-2/3" ref={vitalsRef}>
            <h3 className="tw-mt-14">
              Vitals / Health Indicators Prior to Admission
            </h3>
            <div className="tw-space-y-5">
              <div className="form-group">
                <MyTextBox
                  label={"Temperature"}
                  maxLength={20}
                  value={formData?.temperature}
                  setValue={(e) =>
                    handleInputChange("temperature", e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <MyTextBox
                  label={"Pulse - Beats Per Minute (bpm)"}
                  maxLength={100}
                  value={formData?.pulse}
                  setValue={(e) => handleInputChange("pulse", e.target.value)}
                />
              </div>
              <div className="form-group">
                <MyTextBox
                  label={"Respirations - Breaths Per Minute (bpm)"}
                  maxLength={100}
                  value={formData?.respirations}
                  setValue={(e) =>
                    handleInputChange("respirations", e.target.value)
                  }
                />
              </div>
              <div className="form-group">
                <MyTextBox
                  label={"Blood Pressure - Systolic / Diastolic"}
                  maxLength={50}
                  value={formData?.bloodPressureSystolicDiastolic}
                  setValue={(e) =>
                    handleInputChange(
                      "bloodPressureSystolicDiastolic",
                      e.target.value
                    )
                  }
                />
              </div>
              <div className="form-group">
                <label className="tw-block tw-font-bold">
                  Is Patient on Cardiac Medications?
                </label>
                <fieldset className="fw-normal tw-mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="cardiacMedicationsYes"
                      checked={formData?.cardiacMedications === "Yes"}
                      id="cardiacMedicationsYes"
                      value={"Yes"}
                      onChange={(e) =>
                        handleInputChange(
                          "cardiacMedications",
                          e.currentTarget.value ? "Yes" : "No"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="cardiacMedicationsNo"
                      checked={formData?.cardiacMedications === "No"}
                      id="cardiacMedicationsNo"
                      value={"No"}
                      onChange={(e) =>
                        handleInputChange(
                          "cardiacMedications",
                          e.currentTarget.value ? "No" : "Yes"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsNo"
                    >
                      No
                    </label>
                  </div>
                  {formData?.cardiacMedications === "Yes" && (
                    <div className="form-group">
                      <MyTextBox
                        value={formData?.cardiacMedicationsNotes}
                        setValue={(e) =>
                          handleInputChange(
                            "cardiacMedicationsNotes",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )}
                </fieldset>
              </div>
              <div className="form-group">
                <label className="tw-block tw-font-bold">
                  Is Patient on O<sub>2</sub>?
                </label>
                <fieldset className="fw-normal tw-mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="patientOnO2Yes"
                      checked={formData?.patientOnO2 === "Yes"}
                      id="patientOnO2Yes"
                      value={"Yes"}
                      onChange={(e) =>
                        handleInputChange(
                          "patientOnO2",
                          e.currentTarget.value ? "Yes" : "No"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="patientOnO2No"
                      checked={formData?.patientOnO2 === "No"}
                      id="patientOnO2No"
                      value={"No"}
                      onChange={(e) =>
                        handleInputChange(
                          "patientOnO2",
                          e.currentTarget.value ? "No" : "Yes"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsNo"
                    >
                      No
                    </label>
                  </div>
                  {formData?.patientOnO2 === "Yes" && (
                    <div className="form-group tw-mt-2">
                      <MyTextBox
                        label={
                          <span>
                            O<sub>2</sub> Saturation
                          </span>
                        }
                        maxLength={100}
                        value={formData?.o2Saturation}
                        setValue={(e) =>
                          handleInputChange("o2Saturation", e.target.value)
                        }
                      />
                    </div>
                  )}
                </fieldset>
              </div>

              <div className="form-group">
                <label className="tw-block tw-font-bold">
                  Blood Sugar Out of Range for Patient?
                </label>
                <fieldset className="fw-normal tw-mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="bloodSugarRangeOutOfPatientYes"
                      checked={formData?.bloodSugarRangeOutOfPatient === "Yes"}
                      id="bloodSugarRangeOutOfPatientYes"
                      value={"Yes"}
                      onChange={(e) =>
                        handleInputChange(
                          "bloodSugarRangeOutOfPatient",
                          e.currentTarget.value ? "Yes" : "No"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="bloodSugarRangeOutOfPatientNo"
                      checked={formData?.bloodSugarRangeOutOfPatient === "No"}
                      id="bloodSugarRangeOutOfPatientNo"
                      value={"No"}
                      onChange={(e) =>
                        handleInputChange(
                          "bloodSugarRangeOutOfPatient",
                          e.currentTarget.value ? "No" : "Yes"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsNo"
                    >
                      No
                    </label>
                  </div>
                  {formData?.bloodSugarRangeOutOfPatient === "Yes" && (
                    <div className="form-group tw-mt-2">
                      <MyTextBox
                        label={"Blood Sugar"}
                        maxLength={100}
                        value={formData?.bloodSugar}
                        setValue={(e) =>
                          handleInputChange("bloodSugar", e.target.value)
                        }
                      />
                    </div>
                  )}
                </fieldset>
              </div>
              <div className="form-group">
                <label htmlFor="loc" className="fw-bold">
                  Recent Changes in Health Indicators (Signs, Symptoms or
                  Baselines) - Select all that apply
                </label>
                <MyCustomSelect
                  mode="true"
                  selectOptions={healthIndicatorOptions}
                  myCallBack={onHealthIndicatorsChange}
                  selectedValue={healthIndicators}
                />
                {healthIndicators.length > 0 && (
                  <div className="form-group tw-mt-4">
                    <label className="fw-bold tw-mb-1">
                      Describe the Options Selected Above
                    </label>
                    <TextArea
                      className="form-control"
                      maxLength={"1000"}
                      value={formData?.healthIndicatorsNotes}
                      onChange={(e) =>
                        handleInputChange(
                          "healthIndicatorsNotes",
                          e.target.value
                        )
                      }
                    />
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="tw-block tw-font-bold">
                  Date of Last Provider Visit
                </label>
                <DatePicker
                  className="tw-w-48"
                  placeholder="YYYY-MM-DD"
                  format={"YYYY-MM-DD"}
                  value={
                    formData?.dateOfLastProvider
                      ? dayjs(formData?.dateOfLastProvider)
                      : ""
                  }
                  onChange={(date, dateString) =>
                    handleDateChange("dateOfLastProvider", dateString)
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="selectedOption" className="fw-bold">
                  Notes from EMR or Follow Up Discussion
                </label>
                <TextArea
                  className="form-control"
                  maxLength={"1000"}
                  value={formData?.o2EmrNotes}
                  onChange={(e) =>
                    handleInputChange("o2EmrNotes", e.target.value)
                  }
                />
              </div>
            </div>
          </div>

          {/* ---------------------------------------Part - Two------------------------------- */}

          <div id="opportunities" className="tw-w-2/3" ref={opportunitiesRef}>
            <h3 className="tw-mt-14">Improvement / Opportunities</h3>
            <div className="tw-space-y-5">
              <div className="form-group">
                <label htmlFor="loc" className="fw-bold">
                  Possible Causes of admission - Select all that apply
                </label>
                <MyCustomSelect
                  mode="true"
                  selectOptions={CauseOfAdmissionOptions}
                  myCallBack={onposCausesOfAdmissionChange}
                  selectedValue={posCausesOfAdmission}
                />
                {posCausesOfAdmission.some(
                  (item) => item.value === "Other"
                ) && (
                  <div className="col-md-10 col-lg-auto">
                    <div className="form-group">
                      <MyTextBox
                        value={formData?.possibleCausesOfAdmissionOther}
                        setValue={(e) =>
                          handleInputChange(
                            "possibleCausesOfAdmissionOther",
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label className="tw-font-bold" htmlFor="hospitalname">
                  Was there an opportunity to treat the patient in place?
                </label>
                <fieldset className="fw-normal tw-mt-2">
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="opportunityToTreatThePatientYes"
                      checked={formData?.opportunityToTreatThePatient === "Yes"}
                      id="opportunityToTreatThePatientYes"
                      value={"Yes"}
                      onChange={(e) =>
                        handleInputChange(
                          "opportunityToTreatThePatient",
                          e.currentTarget.value ? "Yes" : "No"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsYes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="opportunityToTreatThePatientNo"
                      checked={formData?.opportunityToTreatThePatient === "No"}
                      id="opportunityToTreatThePatientNo"
                      value={"No"}
                      onChange={(e) =>
                        handleInputChange(
                          "opportunityToTreatThePatient",
                          e.currentTarget.value ? "No" : "Yes"
                        )
                      }
                    />
                    <label
                      className="form-check-label"
                      htmlFor="isSocialWorkerNeedsNo"
                    >
                      No
                    </label>
                  </div>

                  {formData?.opportunityToTreatThePatient === "Yes" && (
                    <MyTextBox
                      value={formData?.opportunityToTreatThePatientNotes}
                      setValue={(e) =>
                        handleInputChange(
                          "opportunityToTreatThePatientNotes",
                          e.target.value
                        )
                      }
                    />
                  )}
                </fieldset>
              </div>
              <div className="form-group">
                <label htmlFor="loc" className="fw-bold">
                  Potential Causes - Select all that apply
                </label>
                <MyCustomSelect
                  mode="true"
                  selectOptions={PotentialCauseOptions}
                  myCallBack={PotentialChancesChange}
                  selectedValue={potentialCauses}
                />
              </div>
              <div className="form-group">
                <label htmlFor="loc" className="fw-bold">
                  Recommendations for Improvement - Select all that apply
                </label>
                <MyCustomSelect
                  mode="true"
                  selectOptions={ImprovementOptions}
                  myCallBack={RecommforImprovChange}
                  selectedValue={recommForImprov}
                />
              </div>
              <div className="form-group">
                <label htmlFor="selectedOption" className="fw-bold">
                  RCA Summary Notes
                </label>
                <TextArea
                  className="form-control"
                  maxLength={"1000"}
                  value={formData?.medicalSummaryNotes}
                  onChange={(e) =>
                    handleInputChange("medicalSummaryNotes", e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatButton.BackTop />
    </>
  );
}
