import React, { useState } from "react";
import useSWR from "swr";
import { openNotification } from "../../common/elements/myNotification";
import { fetcherhn } from "../../services/fectcher";
import { WarningOutlined } from "@ant-design/icons";
import {
  Button,
  Drawer,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import Search from "antd/es/input/Search";
import { deleteOutboundCall } from "../../api/CareEventSCPApi";
import OutboundCallAdd from "./OutboundCallAdd";
import { MdEventNote } from "react-icons/md";
import { handleToggle } from "../../common/elements/toggleSeeMore";
import { usePickList } from "../../services/queries";
import { useMyContext } from "../../common/elements/myContext";
import { FaEdit, FaMobileAlt, FaRegTrashAlt } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import SendEmail from "../../common/elements/SendEmail";

const OutboundCall = (props) => {
  const initialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid,
    providerNameLabel: "",
    providerNameValue: "",
    providerNameOther: "",
    nporpaLabel: "",
    nporpaValue: "",
    nporpaOther: "",
    phoneNumbers: "",
    team: "",
    changeOfCondition: "",
    lastNurseVisit: "",
    erVisitHospitaliastion: "",
    callFrequency: "",
    comments: "",
    createdBy: sessionStorage.getItem("userName"),
    modifiedBy: "",
  };
  const { formatPhoneNumber } = useMyContext();

  const { data: npPiOptions } = useSWR("GetNpPaList", fetcherhn);
  const teamOptions = usePickList(34).data;
  const callFrequencyOptions = usePickList(38).data;

  const [editRecord, setEditRecord] = useState(initialReqState);
  const [query, setQuery] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `GetOutBoundCall?id=${props.mbiid}`,
    fetcherhn
  );

  console.log(data);

  const handleCancel = (e) => {
    setIsModalOpen(false);

    setEditRecord(initialReqState);
  };

  const handleModalClose = (e) => {
    setEditRecord(initialReqState);
    setMail(false);
  };

  const handleOk = async (e) => {
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(initialReqState);
  };

  const edit = (record) => {
    console.log("record", record);
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const [mail, setMail] = useState(false);

  const sendDatatoEmail = (record) => {
    setMail(true);
    setEditRecord(record);
  };

  const handleDelete = async (record) => {
    const body = {
      ...record,
      modifiedBy: sessionStorage.getItem("userName"),
    };
    const deleteResult = await deleteOutboundCall(body);
    console.log("deleteResult", deleteResult);
    if (deleteResult.status) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "Outbound Call Deleted Successfully!"
      );
    }
    setEditRecord(initialReqState);
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const columns = [
    {
      key: 1,
      title: "NP / PA",
      dataIndex: "nporpaLabel",
      render: (_, data) => {
        return (
          <>
            <div>{data.nporpaLabel}</div>
            {data.nporpaLabel === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.nporpaOther}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 2,
      title: "Provider",
      dataIndex: "providerNameLabel",
      render: (_, data) => {
        return (
          <>
            <div>{data.providerNameLabel}</div>
            {data.providerNameLabel === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.providerNameOther}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 4,
      title: "Phone Number",
      dataIndex: "phoneNumbers",
      render: (phoneNumbers) => (
        <>
          {phoneNumbers ? (
            <>
              <FaMobileAlt
                size={20}
                style={{
                  color: "var(--bs-gray-500)",
                  marginRight: "0.5rem",
                  marginTop: "-2px",
                }}
              />
              <a href={`tel:${formatPhoneNumber(phoneNumbers)}`}>
                {formatPhoneNumber(phoneNumbers)}
              </a>
            </>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      key: 5,
      title: "Team",
      dataIndex: "team",
    },
    {
      key: 6,
      title: "Change of Condition",
      dataIndex: "changeOfCondition",
    },
    {
      key: 7,
      title: "Last Nurse Visit",
      dataIndex: "lastNurseVisit",
    },
    {
      key: 8,
      title: "ER Visit / Hospitalization",
      dataIndex: "erVisitHospitaliastion",
    },
    {
      key: 9,
      title: "Call Frequency",
      dataIndex: "callFrequencyValue",
    },
    {
      key: 10,
      title: "Comments",
      dataIndex: "comments",
      width: 500,
      render: (text, record) => {
        const isExpanded = expandedRows.includes(record.key);
        const content =
          text.length > 100 ? (
            <span>
              {isExpanded ? text : `${text.substring(0, 100)}...`}
              <Button
                type="link"
                onClick={() => handleToggle(record.key, setExpandedRows)}
              >
                {isExpanded ? "See less" : "See more"}
              </Button>
            </span>
          ) : (
            text
          );

        return <div>{content}</div>;
      },
    },
    {
      key: 11,
      title: "Created By",
      dataIndex: "createdBy",
      render: (_, { createdBy }) => {
        const [name] = createdBy.split("@");
        return (
          <>
            <div>{name}</div>
          </>
        );
      },
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      fixed: "right",
      width: 110,
      render: (_, record) => (
        <div>
          <Space>
            <Tooltip title="Edit">
              <FaEdit
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => edit(record)}
                color="blue"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Ok to Delete?"
                onConfirm={() => handleDelete(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FaRegTrashAlt
                  size={20}
                  className=" tw-cursor-pointer"
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Send Email">
              <FiSend
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => sendDatatoEmail(record)}
                color="green"
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "nporpaLabel",
          "nporpaOther",
          "providerNameLabel",
          "providerNameOther",
          "phoneNumbers",
          "team",
          "changeOfCondition",
          "lastNurseVisit",
          "erVisitHospitaliastion",
          "callFrequency",
          "comments",
          "createdBy",
        ].some((key) =>
          (
            item[key]?.toString()?.toLowerCase().replace(/_/g, " ") ?? ""
          ).includes(query?.toLowerCase().replace(/_/g, " ") ?? "")
        );
      })
    : data;

  if (isLoading) {
    return <Skeleton active={isLoading} />;
  }

  return (
    <>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          Outbound Call
        </p>

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4 "
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          open={isModalOpen}
          title={"Add/Update Outbound Call"}
          onClose={handleCancel}
          width={900}
        >
          <OutboundCallAdd
            callback={handleOk}
            editRecord={editRecord}
            nppaOptions={npPiOptions}
            teamOptions={teamOptions}
            callFrequencyOptions={callFrequencyOptions}
          />
        </Drawer>
      )}
      <Table
        bordered
        size="middle"
        dataSource={finalResult}
        columns={columns}
        virtual
        scroll={{
          x: 2000,
          y: 1000,
        }}
      />

      {mail && (
        <SendEmail
          open={mail}
          close={handleModalClose}
          editRecord={editRecord}
          modalTitle={"Outbound Call"}
          patientName={props?.patient?.label}
        />
      )}
    </>
  );
};

export default OutboundCall;
