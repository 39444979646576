import React, { useState } from "react";
import useSWR from "swr";
import { fetcherhn } from "../../services/fectcher";
import { openNotification } from "../../common/elements/myNotification";
import { deleteDmeRequest } from "../../api/CareEventSCPApi";
import {
  Button,
  Drawer,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import { WarningOutlined } from "@ant-design/icons";

import Search from "antd/es/input/Search";
import DMERequestAdd from "./DMERequestAdd";
import { usePickList } from "../../services/queries";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import SendEmail from "../../common/elements/SendEmail";

const DMERequest = (props) => {
  const reqStatPL = usePickList(26).data;

  const initialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid,
    urgent: "",
    facilityName: "",
    nameOfDMERequest: "",
    dateOfDMESubmission: "",
    palliativeProviderRequestingDME: "",
    reasonForDMERequest: "",
    IPA: "",
    serviceProvider: "",
    authoriastionNo: "",
    requestStatus: "",
    deliveryConfirmation: "",
    createdBy: sessionStorage.getItem("userName"),
    modifiedBy: "",
  };

  const [editRecord, setEditRecord] = useState(initialReqState);
  const [query, setQuery] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `GetDmeRequest?id=${props.mbiid}`,
    fetcherhn
  );

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setEditRecord(initialReqState);
  };

  const handleModalClose = (e) => {
    setEditRecord(initialReqState);
    setMail(false);
  };

  const [mail, setMail] = useState(false);

  const sendDatatoEmail = (record) => {
    setMail(true);
    setEditRecord(record);
  };
  const handleOk = async (e) => {
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(initialReqState);
  };

  const edit = (record) => {
    console.log("record", record);
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleDelete = async (record) => {
    const body = {
      ...record,
      modifiedBy: sessionStorage.getItem("userName"),
    };
    const deleteResult = await deleteDmeRequest(body);
    console.log("deleteResult", deleteResult);
    if (deleteResult.status) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "DME Request Deleted Successfully!"
      );
    }
    setEditRecord(initialReqState);
  };

  const columns = [
    {
      key: 1,
      title: "Urgent",
      dataIndex: "urgent",
    },
    {
      key: 2,
      title: "Name of DME Requested",
      dataIndex: "nameOfDMERequest",
    },
    {
      key: 3,
      title: "Date of DME Request Submission",
      dataIndex: "dateOfDMESubmission",
    },
    {
      key: 4,
      title: "Palliative Provider Requesting DME",
      dataIndex: "palliativeProviderRequestingDME",
    },
    {
      key: 5,
      title: "Reason why DME is being Requested",
      dataIndex: "reasonForDMERequest",
    },
    {
      key: 6,
      title: "IPA",
      dataIndex: "IPA",
    },
    {
      key: 7,
      title: "Facility Name",
      dataIndex: "facilityName",
    },
    {
      key: 8,
      title: "Servicing Provider",
      dataIndex: "serviceProvider",
    },
    {
      key: 9,
      title: "Authorization Number",
      dataIndex: "authoriastionNo",
    },
    {
      key: 10,
      title: "Request Status",
      dataIndex: "requestStatus",
    },
    {
      key: 11,
      title: "Delivery Confirmation",
      dataIndex: "deliveryConfirmation",
    },
    {
      key: 12,
      title: "Created By",
      dataIndex: "createdBy",
      render: (createdBy) => <div>{createdBy.split("@")[0]}</div>,
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      fixed: "right",
      width: 110,
      render: (_, record) => (
        <div>
          <Space>
            <Tooltip title="Edit">
              <FaEdit
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => edit(record)}
                color="blue"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Ok to Delete?"
                onConfirm={() => handleDelete(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FaRegTrashAlt
                  size={20}
                  className=" tw-cursor-pointer"
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Send Email">
              <FiSend
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => sendDatatoEmail(record)}
                color="green"
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "urgent",
          "nameOfDMERequest",
          "dateOfDMESubmission",
          "facilityName",
          "palliativeProviderRequestingDME",
          "reasonForDMERequest",
          "reasonForOrder",
          "IPA",
          "serviceProvider",
          "authoriastionNo",
          "requestStatus",
          "deliveryConfirmation",
          "createdBy",
        ].some((key) =>
          (item[key]?.toString()?.toLowerCase() ?? "").includes(
            query?.toLowerCase() ?? ""
          )
        );
      })
    : data;

  if (isLoading) {
    return <Skeleton active={isLoading} />;
  }

  return (
    <>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          DME Request
        </p>

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4 "
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          open={isModalOpen}
          title={"Add/Update DME Request"}
          onClose={handleCancel}
          width={900}
        >
          <DMERequestAdd
            callback={handleOk}
            editRecord={editRecord}
            reqStatPL={reqStatPL}
          />
        </Drawer>
      )}
      <Table
        bordered
        size="middle"
        dataSource={finalResult}
        columns={columns}
        virtual
        scroll={{
          x: 2000,
          y: 1000,
        }}
      />
      {mail && (
        <SendEmail
          open={mail}
          close={handleModalClose}
          editRecord={editRecord}
          modalTitle={"DME Request"}
          patientName={props?.patient?.label}
        />
      )}
    </>
  );
};

export default DMERequest;
