import React, { useState } from "react";
import { fetcher } from "../../services/fectcher";
import useSWR from "swr";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import { Button, Drawer, Space, Table, Typography, Popconfirm } from "antd";
import Search from "antd/es/input/Search";
import { SNF } from "./SNF";
import { usePickList } from "../../services/queries";
import { WarningOutlined } from "@ant-design/icons";
import { deleteSnf } from "../../api/ToCareApi";
import { MdEventNote } from "react-icons/md";
import { openNotification } from "../../common/elements/myNotification";
import { useMyContext } from "../../common/elements/myContext";
import { handleToggle } from "../../common/elements/toggleSeeMore";

export const SNFView = (props) => {
  const intialReqState = {
    key: "",
    guid: "",
    mbi: props?.mbiid || "",
    notificationSource: "",
    notificationSourceOtherNotes: "",
    admittingPhysician: "",
    reasonAdmittingDx: "",
    startDate: "",
    endDate: "",
    deceasedDate: "",
    dischargeDisposition: "",
    dischargeDispositionOtherNotes: "",
    partA: "",
    partB: "",
    snfServices: "",
    snfServicesOtherNotes: "",
    createdBy: sessionStorage.getItem("userName") || "",
    modifiedBy: "",
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { calculateDateRange } = useMyContext();

  const { fromDate, toDate } = calculateDateRange();
  const [editRecord, setEditRecord] = useState(intialReqState);
  const [query, setQuery] = useState("");

  const { data, mutate, isLoading } = useSWR(
    `getSnfForDateRange?mbi=${props.mbiid}&fromDate=${fromDate}&toDate=${toDate}`,
    fetcher
  );

  const sourceOfNotificationOptions = usePickList(11).data;
  const dischargeDispositionOptions = usePickList(16).data;
  const snfServiceOptions = usePickList(17).data;

  const edit = (record) => {
    console.log("record", record);
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleOk = async (e) => {
    console.log(e);
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };
  const handleDelete = async (record) => {
    const body = { ...record, modifiedBy: sessionStorage.getItem("userName") };
    const deleteResult = await deleteSnf(body);
    console.log("deleteResult", deleteResult.statusText);
    if (deleteResult.status === 200) {
      mutate();
      openNotification("success", "Deleted", "SNF Deleted Successfully!");
    } else {
      openNotification("error", "Error", "SNF failed to delete.");
    }
  };

  const notificationSourceLabel = (notificationSource) => {
    const notifValue = sourceOfNotificationOptions?.find(
      (item) => item.value === notificationSource
    );
    return notifValue ? notifValue.label : "";
  };
  const dischargeDispositionLabel = (dischargeReason) => {
    const dischargeValue = dischargeDispositionOptions?.find(
      (item) => item.value === dischargeReason
    );
    return dischargeValue ? dischargeValue.label : "";
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const columns = [
    {
      key: 1,
      title: "Notification Source",
      dataIndex: "notificationSource",
      width: 100,
      render: (_, data) => {
        return (
          <>
            <div>{notificationSourceLabel(data.notificationSource)}</div>
            {data.notificationSource === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.notificationSourceOtherNotes}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 3,
      title: "Admitting Physician",
      dataIndex: "admittingPhysician",
      width: 100,
    },
    {
      key: 4,
      title: "Reason/Admitting Dx",
      dataIndex: "reasonAdmittingDx",
      width: 100,
      render: (text, record) => {
        const isExpanded = expandedRows.includes(record.key);
        const content =
          text.length > 100 ? (
            <span>
              {isExpanded ? text : `${text.substring(0, 100)}...`}
              <Button
                type="link"
                onClick={() => handleToggle(record.key, setExpandedRows)}
              >
                {isExpanded ? "See less" : "See more"}
              </Button>
            </span>
          ) : (
            text
          );

        return <div>{content}</div>;
      },
    },
    {
      key: 5,
      title: "Start Date",
      dataIndex: "startDate",
      width: 100,
    },
    {
      key: 6,
      title: "End Date",
      dataIndex: "endDate",
      width: 100,
    },

    {
      key: 8,
      title: "Deceased Date",
      dataIndex: "deceasedDate",
      width: 100,
    },
    {
      key: 9,
      title: "Discharge Disposition",
      dataIndex: "dischargeDisposition",
      width: 100,
      render: (_, data) => {
        return (
          <>
            <div>{dischargeDispositionLabel(data.dischargeDisposition)}</div>
            {data.dischargeDisposition === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.dischargeDispositionOtherNotes}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 9,
      title: "Skilled Part A",
      dataIndex: "partA",
      width: 100,
    },
    {
      key: 9,
      title: "Skilled Part B",
      dataIndex: "partB",
      width: 100,
    },
    {
      key: 9,
      title: "SNF Services",
      dataIndex: "snfServices",
      width: 100,
      render: (_, data) => {
        const isOther = data.snfServices.includes("Other");

        return (
          <>
            <div>{data.snfServices}</div>
            {isOther && (
              <div className="tw-text-gray-500">
                <MdEventNote size={20} className="tw-mr-1 tw-text-gray-400" />
                {data.snfServicesOtherNotes}
              </div>
            )}
          </>
        );
      },
    },

    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      width: 70,
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Typography.Link onClick={() => edit(record)}>Edit</Typography.Link>
          <Typography.Link type="danger">
            <Popconfirm
              title="Ok to Delete?"
              onConfirm={() => handleDelete(record)}
              icon={
                <WarningOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
            >
              Delete
            </Popconfirm>
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "notificationSource",
          "notificationSourceOtherNotes",
          "admittingPhysician",
          "reasonAdmittingDx",
          "startDate",
          "endDate",
          "deceasedDate",
          "partA",
          "partB",
          "dischargeDisposition",
          "dischargeDispositionOtherNotes",
          "snfServices",
          "snfServicesOtherNotes",
          "createdBy",
        ].some((key) => {
          if (key === "notificationSource") {
            const normalizedQuery =
              query?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            const sourceValue =
              item[key]?.toString()?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            return sourceValue.includes(normalizedQuery);
          } else if (key === "dischargeDisposition") {
            const normalizedQuery =
              query?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            const sourceValue =
              item[key]?.toString()?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            return sourceValue.includes(normalizedQuery);
          } else if (key === "snfServices") {
            const normalizedQuery =
              query?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            const sourceValue =
              item[key]?.toString()?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            return sourceValue.includes(normalizedQuery);
          } else {
            return (item[key]?.toString()?.toLowerCase() ?? "").includes(
              query?.toLowerCase() ?? ""
            );
          }
        });
      })
    : data;

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          SNF
        </p>

        {isLoading && <MyBackdropSpinner show={isLoading} />}

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4"
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          title="SNF"
          width={800}
          onClose={() => {
            setIsModalOpen(false);
            setEditRecord(intialReqState);
          }}
          open={isModalOpen}
        >
          <SNF
            mbiid={props?.mbiid}
            editRecord={editRecord}
            callback={handleOk}
            son={sourceOfNotificationOptions}
            ddo={dischargeDispositionOptions}
            snfs={snfServiceOptions}
          />
        </Drawer>
      )}

      <Table
        bordered
        size="middle"
        dataSource={finalResult}
        columns={columns}
      />
    </div>
  );
};
