import React, { useEffect, useState } from "react";
import PageTitle from "../Common/PageTitle";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import MyCustomSelect, {
  formatOptionLabel,
} from "../../common/elements/myCustomSelect";
import IPAdmissionTable from "./IPAdmissionTable";
import { SNFView } from "./SNFView";
import EDView from "./EDView";
import HospiceView from "./HospiceView";
import DeathView from "./DeathView";
import { fetcher } from "../../services/fectcher";
import useSWR from "swr";
import { useLocation } from "react-router-dom";
import { Space } from "antd";

const TocView = () => {
  const { data: allPatients, isLoading } = useSWR(
    `GetPatientsListNew?lob=${"HN,PAC"}`,
    fetcher
  );

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.patient) {
      setSelecdtedPatient(location?.state?.patient);
      if (location.state.type === "ipa")
        setSelecdtedEvent({ value: "ipa", label: "IP Admission" });
      if (location.state.type === "edv")
        setSelecdtedEvent({ value: "edv", label: "ED Visit" });
    }
  }, [location?.state]);

  const [selecdtedPatient, setSelecdtedPatient] = useState();
  const [selecdtedEvent, setSelecdtedEvent] = useState();

  return (
    <>
      <div className="tw-flex tw-content-center">
        <PageTitle title={"Transition of Care"} />
      </div>

      {isLoading && <MyBackdropSpinner show={isLoading} />}
      <div className="tw-flex tw-place-content-start tw-gap-4">
        <div className="tw-basis-1/3">
          <label htmlFor="patientName" className=" fw-bold">
            Patient
          </label>
          <MyCustomSelect
            selectOptions={allPatients}
            selectedValue={selecdtedPatient}
            myCallBack={(e) => {
              setSelecdtedPatient(e);
              setSelecdtedEvent("");
            }}
            formatOptionLabel={formatOptionLabel}
          />
        </div>
        <div className="tw-basis-1/3">
          <label htmlFor="eventType" className=" fw-bold">
            Event Type
          </label>
          <MyCustomSelect
            selectOptions={[
              { value: "ipa", label: "IP Admission" },
              { value: "edv", label: "ED Visit" },
              { value: "snf", label: "SNF" },
              { value: "death", label: "Death" },
              { value: "hospice", label: "Hospice" },
            ]}
            selectedValue={selecdtedEvent}
            disable={selecdtedPatient?.value ? false : true}
            myCallBack={(e) => setSelecdtedEvent(e)}
            // errorMessage={pageError?.patientId}
          />
        </div>
      </div>
      <div>
        {selecdtedEvent?.value === "ipa" && (
          <IPAdmissionTable patient={selecdtedPatient} />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "edv" && (
          <EDView patient={selecdtedPatient} />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "snf" && (
          <SNFView mbiid={selecdtedPatient?.value} />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "hospice" && (
          <HospiceView mbiid={selecdtedPatient?.value} />
        )}
      </div>
      <div>
        {selecdtedEvent?.value === "death" && (
          <DeathView mbiid={selecdtedPatient?.value} />
        )}
      </div>
    </>
  );
};

export default TocView;
