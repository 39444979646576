import React, { useEffect, useState } from "react";
import MyTextBox from "../../common/elements/myTextbox";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import { openNotification } from "../../common/elements/myNotification";
import { addDmeRequest } from "../../api/CareEventSCPApi";

const DMERequestAdd = ({ editRecord, callback, reqStatPL }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );

  const [pageError, setPageError] = useState({});
  const [reqStat, setReqStat] = useState();
  const [disable, setDisable] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const handleSelectChange = (e) => {
    setReqStat(e);
    setFormData({
      ...formData,
      requestStatus: e ? e.value : "",
    });
    setPageError({
      ...pageError,
      requestStatus: e ? "" : "Required",
    });
  };

  const handleDateChange = (fieldName, dateString) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: dateString ? dateString : "",
    }));
    setPageError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: dateString ? "" : "Required",
    }));
  };

  useEffect(() => {
    if (editRecord?.guid) {
      let osTemp = [];
      if (editRecord?.requestStatus) {
        osTemp = reqStatPL?.filter((item) => {
          return item.value === editRecord?.requestStatus;
        });
      }
      setReqStat(osTemp);
    }
  }, [editRecord, reqStatPL]);

  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (
      !formData?.urgent ||
      !formData?.nameOfDMERequest ||
      !formData?.dateOfDMESubmission ||
      !formData?.palliativeProviderRequestingDME ||
      !formData?.reasonForDMERequest ||
      !formData?.IPA ||
      !formData?.serviceProvider ||
      !formData?.authoriastionNo ||
      !formData?.requestStatus ||
      !formData?.deliveryConfirmation
    ) {
      pageErrorTemp.urgent = formData?.urgent ? "" : "Required";
      pageErrorTemp.nameOfDMERequest = formData?.nameOfDMERequest
        ? ""
        : "Required";
      pageErrorTemp.dateOfDMESubmission = formData?.dateOfDMESubmission
        ? ""
        : "Required";
      pageErrorTemp.palliativeProviderRequestingDME =
        formData?.palliativeProviderRequestingDME ? "" : "Required";
      pageErrorTemp.reasonForDMERequest = formData?.reasonForDMERequest
        ? ""
        : "Required";
      pageErrorTemp.IPA = formData?.IPA ? "" : "Required";
      pageErrorTemp.serviceProvider = formData?.serviceProvider
        ? ""
        : "Required";
      pageErrorTemp.authoriastionNo = formData?.authoriastionNo
        ? ""
        : "Required";
      pageErrorTemp.requestStatus = formData?.requestStatus ? "" : "Required";
      pageErrorTemp.deliveryConfirmation = formData?.deliveryConfirmation
        ? ""
        : "Required";

      setPageError(pageErrorTemp);
      return;
    }
    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      const response = await addDmeRequest(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification("success", "Added", "DME Request Added Successfully!");
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "DME Request Updated Successfully!"
        );
      } else {
        openNotification("error", "Error", "DME Request Failed to Add!");
      }
      console.log("response", response);
      setFormData(editRecord);
      setDisable(false);
      callback(response);
    } catch (e) {
      return e;
    }
  };

  return (
    <div className="tw-relative tw-px-2">
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="urgent">
          Urgent?
        </label>
        <fieldset className="fw-normal">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="urgent"
              checked={formData?.urgent === "Yes"}
              id="Yes"
              value={"Yes"}
              onChange={(e) =>
                handleInputChange(
                  "urgent",
                  e.currentTarget.value ? "Yes" : "No"
                )
              }
            />
            <label className="form-check-label" htmlFor="Yes">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="urgent"
              checked={formData?.urgent === "No"}
              id="No"
              value={"No"}
              onChange={(e) =>
                handleInputChange(
                  "urgent",
                  e.currentTarget.value ? "No" : "Yes"
                )
              }
            />
            <label className="form-check-label" htmlFor="No">
              No
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="urgent"
              checked={formData?.urgent === "Unknown"}
              id="Unknown"
              value={"Unknown"}
              onChange={(e) =>
                handleInputChange(
                  "urgent",
                  e.currentTarget.value ? "Unknown" : ""
                )
              }
            />
            <label className="form-check-label" htmlFor="No">
              Unknown
            </label>
          </div>
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.urgent}
          </span>
        </fieldset>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Name of DME Requested"}
            value={formData?.nameOfDMERequest}
            errorMessage={pageError?.nameOfDMERequest}
            setValue={(e) =>
              handleInputChange("nameOfDMERequest", e.target.value)
            }
            maxLength={100}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11 form-group">
        <div className="tw-flex ">
          <label className="tw-font-bold" htmlFor="dateTime">
            Date of DME Request Submission
          </label>
        </div>
        <DatePicker
          use12Hours
          className="form-control tw-mb-3"
          onChange={(dateString) =>
            handleDateChange("dateOfDMESubmission", dateString)
          }
          format={"YYYY-MM-DD"}
          value={
            formData?.dateOfDMESubmission
              ? dayjs(formData.dateOfDMESubmission)
              : ""
          }
          style={{
            border: pageError?.dateOfDMESubmission ? "1px solid red" : "",
          }}
        />
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Palliative Provider Requesting DME"}
            value={formData?.palliativeProviderRequestingDME}
            errorMessage={pageError?.palliativeProviderRequestingDME}
            setValue={(e) =>
              handleInputChange(
                "palliativeProviderRequestingDME",
                e.target.value
              )
            }
            maxLength={100}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Reason why DME is being Requested"}
            value={formData?.reasonForDMERequest}
            errorMessage={pageError?.reasonForDMERequest}
            setValue={(e) =>
              handleInputChange("reasonForDMERequest", e.target.value)
            }
            maxLength={100}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"IPA"}
            errorMessage={pageError?.IPA}
            value={formData?.IPA}
            setValue={(e) => handleInputChange("IPA", e.target.value)}
            maxLength={100}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Servicing Provider"}
            errorMessage={pageError?.serviceProvider}
            value={formData?.serviceProvider}
            setValue={(e) =>
              handleInputChange("serviceProvider", e.target.value)
            }
            maxLength={100}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Authorization Number"}
            errorMessage={pageError?.authoriastionNo}
            value={formData?.authoriastionNo}
            setValue={(e) =>
              handleInputChange("authoriastionNo", e.target.value)
            }
            maxLength={50}
          />
        </div>
      </div>
      <div className="col-md-11 col-lg-11 tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Request Status
        </label>
        <MyCustomSelect
          selectOptions={reqStatPL}
          myCallBack={handleSelectChange}
          selectedValue={reqStat}
          errorMessage={pageError?.requestStatus}
        />
      </div>
      <div className="col-md-11 col-lg-11">
        <div className="form-group tw-mb-3">
          <MyTextBox
            label={"Delivery Confirmation"}
            value={formData?.deliveryConfirmation}
            errorMessage={pageError?.deliveryConfirmation}
            setValue={(e) =>
              handleInputChange("deliveryConfirmation", e.target.value)
            }
            maxLength={100}
          />
        </div>
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={!disable ? "btn btn-success" : "btn btn-success disabled"}
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DMERequestAdd;
