import { DatePicker } from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { postIPCommNote } from "../../api/CareEventsApi";
import { openNotification } from "../../common/elements/myNotification";
import MyTextBox from "../../common/elements/myTextbox";

const dateFormat = ["YYYY-MM-DD hh:mm A"];

export const AddIPHospitalCommNote = ({ editRecord, callback }) => {
  console.log("editRecord", editRecord);
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isCMPhoneValid, setIsCMPhoneValid] = useState(true);
  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };
  const onIPCommDateChange = (date, dateString) => {
    //setSelectedDateTime(date || "");
    setFormData({
      ...formData,
      iPCommunicationNoteDateTime: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      iPCommunicationNoteDateTime: dateString ? "" : "Required",
    });
  };
  const onDoAChange = (date, dateString) => {
    setFormData({
      ...formData,
      dateOfAdmission: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      dateOfAdmission: dateString ? "" : "Required",
    });
  };

  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    if (
      !formData?.iPCommunicationNoteDateTime ||
      !formData?.dateOfAdmission ||
      !formData?.hospitalName ||
      !formData?.expectedLengthOfStay
    ) {
      pageErrorTemp.iPCommunicationNoteDateTime =
        formData?.iPCommunicationNoteDateTime ? "" : "Required";
      pageErrorTemp.dateOfAdmission = formData?.dateOfAdmission
        ? ""
        : "Required";
      pageErrorTemp.hospitalName = formData?.hospitalName ? "" : "Required";
      pageErrorTemp.expectedLengthOfStay = formData?.expectedLengthOfStay
        ? ""
        : "Required";
    }

    // Check phone number validity if it is provided
    if (formData?.attendingPhysicianPhoneNumber && !isPhoneValid) {
      pageErrorTemp.attendingPhysicianPhoneNumber = "Invalid phone number";
    }

    if (formData?.hospitalCareManagerPhoneNumber && !isCMPhoneValid) {
      pageErrorTemp.hospitalCareManagerPhoneNumber = "Invalid phone number";
    }

    // If there are any errors, set them and return
    if (Object.keys(pageErrorTemp).some((key) => pageErrorTemp[key] !== "")) {
      setPageError(pageErrorTemp);
      return;
    }

    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    setDisable(true);
    try {
      const response = await postIPCommNote(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Inpatient Hospital Communication Note Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Inpatient Hospital Communication Note Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "Inpatient Hospital Communication Note Failed to Add!!!"
        );
      }
      console.log("response", response);

      callback(response);
    } catch (e) {
      return e;
    }
    setDisable(false);
  };
  //console.log("formData", formData);

  return (
    <div className="tw-relative">
      <div className="tw-flex ">
        <label className="tw-font-bold" htmlFor="doa">
          Date of Admission
        </label>
      </div>
      <DatePicker
        className="form-control tw-mb-3"
        format={"YYYY-MM-DD"}
        value={formData?.dateOfAdmission ? dayjs(formData.dateOfAdmission) : ""}
        onChange={onDoAChange}
        style={{
          border: pageError?.dateOfAdmission ? "1px solid red" : "",
        }}
      />
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalname">
          Hospital Name
        </label>
        <input
          maxLength={100}
          className=" form-control"
          type="text"
          id="hospitalname"
          value={formData?.hospitalName}
          onChange={(e) => handleInputChange("hospitalName", e.target.value)}
          style={{
            border: pageError?.hospitalName ? "1px solid red" : "",
          }}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="attendingPhysicain">
          Attending Physician
        </label>
        <input
          maxLength={100}
          className=" form-control "
          type="text"
          id="attendingPhysicain"
          value={formData?.attendingPhysician}
          onChange={(e) =>
            handleInputChange("attendingPhysician", e.target.value)
          }
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <div className="form-group">
          <MyTextBox
            label="Attending Physician Phone Number#"
            value={formData?.attendingPhysicianPhoneNumber}
            setValue={(e) =>
              handleInputChange("attendingPhysicianPhoneNumber", e.target.value)
            }
            isPhoneNumber={true}
            onValidChange={(e) => setIsPhoneValid(e)}
          />
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="hospitalCareManager">
          Hospital Care/Case Manager
        </label>
        <input
          className=" form-control "
          maxLength={100}
          type="text"
          id="hospitalCareManager"
          value={formData?.hospitalCareManager}
          onChange={(e) =>
            handleInputChange("hospitalCareManager", e.target.value)
          }
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <div className="form-group">
          <MyTextBox
            label="Hospital Care/Case Manager Phone Number#"
            value={formData?.hospitalCareManagerPhoneNumber}
            setValue={(e) =>
              handleInputChange(
                "hospitalCareManagerPhoneNumber",
                e.target.value
              )
            }
            isPhoneNumber={true}
            onValidChange={(e) => setIsCMPhoneValid(e)}
          />
        </div>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="admittingDiagnosis">
          Admitting Diagnosis
        </label>
        <textarea
          maxLength={1000}
          className=" form-control"
          id="admittingDiagnosis"
          name={"admittingDiagnosis"}
          value={formData?.admittingDiagnosis}
          onChange={(e) =>
            handleInputChange("admittingDiagnosis", e.target.value)
          }
        ></textarea>
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="attendingPhysicainpn">
          Expected Length of Stay
        </label>
        <input
          className=" form-control "
          type="number"
          min="0"
          id="attendingPhysicainpn"
          value={formData?.expectedLengthOfStay}
          onChange={(e) =>
            handleInputChange("expectedLengthOfStay", e.target.value)
          }
          style={{
            border: pageError?.expectedLengthOfStay ? "1px solid red" : "",
          }}
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="attendingPhysicainpn">
          Discharge Disposition
        </label>
        <input
          maxLength={200}
          className=" form-control "
          type="text"
          id="attendingPhysicainpn"
          value={formData?.dischargeDisposition}
          onChange={(e) =>
            handleInputChange("dischargeDisposition", e.target.value)
          }
        />
      </div>
      <div className="tw-flex tw-flex-col tw-mb-3">
        <label className="tw-font-bold" htmlFor="notes">
          Notes
        </label>
        <textarea
          className=" form-control"
          id="notes"
          name={"notes"}
          maxLength={3000}
          value={formData?.notes}
          onChange={(e) => handleInputChange("notes", e.target.value)}
        ></textarea>
      </div>
      <div className="tw-flex ">
        <label className="tw-font-bold" htmlFor="dateTime">
          Note Date & Time
        </label>
      </div>
      <DatePicker
        showTime
        use12Hours
        className="form-control tw-mb-3"
        onChange={onIPCommDateChange}
        format={dateFormat}
        value={
          formData?.iPCommunicationNoteDateTime
            ? dayjs(formData.iPCommunicationNoteDateTime)
            : ""
        }
        style={{
          border: pageError?.iPCommunicationNoteDateTime ? "1px solid red" : "",
        }}
      />
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={
            !disable ? "btn btn-success " : "btn btn-success disabled "
          }
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};
