import { Button, Drawer, Popconfirm, Space, Table, Typography } from "antd";
import React, { useState } from "react";
import useSWR from "swr";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import Search from "antd/es/input/Search";
import { fetcher } from "../../services/fectcher";
import { usePickList } from "../../services/queries";
import { WarningOutlined } from "@ant-design/icons";
import DeathEdit from "./DeathEdit";
import { deleteDeath } from "../../api/ToCareApi";
import { MdEventNote } from "react-icons/md";
import { openNotification } from "../../common/elements/myNotification";

const DeathView = (props) => {
  const intialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid,
    notificationSource: "",
    notificationSourceOtherNotes: "",
    deceasedDate: "",
    deathLocationType: "",
    deathLocationTypeOtherNotes: "",
    deathLocation: "",
    deathLocationOtherNotes: "",
    createdBy: sessionStorage.getItem("userName") || "",
    modifiedBy: "",
  };

  const sonDeathOptions = usePickList(12).data;
  const deathLocOptions = usePickList(18).data;
  const deathLocTypeOptions = usePickList(19).data;

  const notificationSourceLabel = (notificationSource) => {
    const notifValue = sonDeathOptions?.find(
      (item) => item.value === notificationSource
    );
    return notifValue ? notifValue.label : "";
  };
  const deathLocationLabel = (deathLocation) => {
    const deathValue = deathLocOptions?.find(
      (item) => item.value === deathLocation
    );
    return deathValue ? deathValue.label : "";
  };
  const deathLocationTypeLabel = (deathLocationType) => {
    const deathtypeValue = deathLocTypeOptions?.find(
      (item) => item.value === deathLocationType
    );
    return deathtypeValue ? deathtypeValue.label : "";
  };

  const [editRecord, setEditRecord] = useState(intialReqState);
  const [query, setQuery] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `getLastDeceasedDetails?mbi=${props.mbiid}`,
    fetcher
  );

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const edit = (record) => {
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleOk = async (e) => {
    console.log(e);
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };
  const handleDelete = async (record) => {
    const body = {
      ...record,
      modifiedBy: sessionStorage.getItem("userName"),
    };
    const deleteResult = await deleteDeath(body);
    console.log("deleteResult", deleteResult.statusText);
    if (deleteResult.status === 200) {
      mutate();
      openNotification("success", "Deleted", "Death deleted successfully!");
    } else {
      openNotification("error", "Error", "Death failed to deleted.");
    }
  };

  const columns = [
    {
      key: 1,
      title: "Notification Source",
      dataIndex: "notificationSource",
      width: 40,
      render: (_, data) => {
        return (
          <>
            <div>{notificationSourceLabel(data.notificationSource)}</div>
            {data.notificationSource === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.notificationSourceOtherNotes}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 2,
      title: "Deceased Date",
      dataIndex: "deceasedDate",
      width: 30,
    },
    {
      key: 3,
      title: "Death Location",
      dataIndex: "deathLocation",
      width: 40,
      render: (_, data) => {
        return (
          <>
            <div>{deathLocationLabel(data.deathLocation)}</div>
            {data.deathLocation === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.deathLocationOtherNotes}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 4,
      title: "Death Location Type",
      dataIndex: "deathLocationType",
      width: 40,
      render: (_, data) => {
        return (
          <>
            <div>{deathLocationTypeLabel(data.deathLocationType)}</div>
            {data.deathLocationType === "Other" && (
              <div className=" tw-text-gray-500">
                <MdEventNote size={20} className=" tw-mr-1 tw-text-gray-400" />
                {data.deathLocationTypeOtherNotes}
              </div>
            )}
          </>
        );
      },
    },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      width: 10,
      fixed: "right",
      render: (_, record) => (
        <Space>
          <Typography.Link onClick={() => edit(record)}>Edit</Typography.Link>
          <Typography.Link type="danger">
            <Popconfirm
              title="Ok to Delete?"
              onConfirm={() => handleDelete(record)}
              icon={
                <WarningOutlined
                  style={{
                    color: "red",
                  }}
                />
              }
            >
              Delete
            </Popconfirm>
          </Typography.Link>
        </Space>
      ),
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "notificationSource",
          "notificationSourceOtherNotes",
          "deceasedDate",
          "deathLocationType",
          "deathLocationTypeOtherNotes",
          "deathLocation",
          "deathLocationOtherNotes",
          "createdBy",
        ].some((key) => {
          if (key === "notificationSource") {
            const normalizedQuery =
              query?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            const sourceValue =
              item[key]?.toString()?.replace(/\s/g, "_")?.toLowerCase() ?? "";
            return sourceValue.includes(normalizedQuery);
          } else {
            return (item[key]?.toString()?.toLowerCase() ?? "").includes(
              query?.toLowerCase() ?? ""
            );
          }
        });
      })
    : data;

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          Death
        </p>

        {isLoading && <MyBackdropSpinner show={isLoading} />}

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              disabled={!isLoading && data[0] ? true : false}
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4"
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          title="Death"
          width={800}
          onClose={handleCancel}
          open={isModalOpen}
        >
          <DeathEdit
            mbiid={props.mbiid}
            patient={props.patient}
            editRecord={editRecord}
            callback={handleOk}
            son={sonDeathOptions}
            dlo={deathLocOptions}
            dlt={deathLocTypeOptions}
          />
        </Drawer>
      )}

      <Table
        bordered
        size="middle"
        dataSource={finalResult}
        columns={columns}
      />
    </div>
  );
};

export default DeathView;
