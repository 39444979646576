import React, { useEffect, useState } from "react";
import MyCustomSelect from "../../common/elements/myCustomSelect";
import MyTextBox from "../../common/elements/myTextbox";
import { DatePicker } from "antd";
import MyTextArea from "../../common/elements/myTextArea";
import { useMyContext } from "../../common/elements/myContext";
import * as TocApi from "../../api/ToCareApi";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import dayjs from "dayjs";

import { openNotification } from "../../common/elements/myNotification";

export const SNF = ({ editRecord, callback, son, ddo, snfs }) => {
  const sourceOfNotificationOptions = son;
  const dischargeDispositionOptions = ddo;
  const snfServiceOptions = snfs;
  const [spinner, setSpinner] = useState(false);

  const [selectedSon, setSelectedSon] = useState("");

  const [snfEndDate, setSNFEndDate] = useState("");
  const [deceasedDate, setDeceasedDate] = useState("");
  const [dischargeDisposition, setDischargeDisposition] = useState();

  const [pageError, setPageError] = useState({});
  const [snfPart, setSNFPart] = useState([]);
  const [snfServices, setSNFServices] = useState([]);

  const { converttoDate } = useMyContext();

  const [reqBody, setReqBody] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );

  useEffect(() => {
    if (editRecord.guid) {
      let sonTemp = [];
      if (editRecord?.notificationSource) {
        sonTemp = sourceOfNotificationOptions?.filter((item) => {
          return item.value === editRecord.notificationSource;
        });
        setSelectedSon(sonTemp[0]);
      }

      let ddTemp = [];
      if (editRecord.dischargeDisposition) {
        ddTemp = dischargeDispositionOptions?.filter((item) => {
          return item.value === editRecord.dischargeDisposition;
        });
        setDischargeDisposition(ddTemp[0]);
      }

      setSNFEndDate(editRecord.endDate ? dayjs(editRecord.endDate) : "");
      setDeceasedDate(
        editRecord.deceasedDate ? dayjs(editRecord.deceasedDate) : ""
      );

      let tempSNF = [];
      if (editRecord.partA === "Yes") {
        tempSNF.push({
          value: "snfa",
          label: "Skilled Part A",
        });
      }
      if (editRecord.partB === "Yes") {
        tempSNF.push({
          value: "snfb",
          label: "Skilled Part B",
        });
      }
      setSNFPart(tempSNF);
      let tempSNFService = [];
      if (snfServiceOptions) {
        const snfServicesArray = editRecord?.snfServices.split(",");
        snfServicesArray.forEach((service) => {
          const filteredServices = snfServiceOptions.filter(
            (snf) => snf.value === service
          );
          tempSNFService.push(...filteredServices);
        });
      }
      setSNFServices(tempSNFService);
    }
  }, [editRecord]);

  const onSonChange = (e) => {
    setSelectedSon(e);
    setReqBody({ ...reqBody, notificationSource: e ? e.value : "" });
    setPageError({
      ...pageError,
      notificationSource: e ? "" : "Required",
    });
    if (e?.value !== "Other") {
      setReqBody({
        ...reqBody,
        notificationSource: e ? e.value : "",
        notificationSourceOtherNotes: "",
      });
    }
  };

  const onSNFStartdateChange = (date, dateString) => {
    setReqBody({
      ...reqBody,
      startDate: dateString ? dateString : "",
    });
    setPageError({
      ...pageError,
      snfStartDate: dateString ? "" : "Required",
    });
  };
  const onSNFEnddateChange = (date, dateString) => {
    //setSNFEndDate(date);
    setReqBody({ ...reqBody, endDate: dateString ? dateString : "" });
    const endDate = dateString ? dayjs(dateString) : null;
    const startDate = reqBody.startDate ? dayjs(reqBody.startDate) : null;

    if (startDate && endDate && endDate.isBefore(startDate)) {
      setPageError({
        ...pageError,
        endDate: "End Date cannot be before start date",
      });
    } else {
      setPageError({
        ...pageError,
        endDate: "",
      });
    }
  };

  const onDeceasedDate = (date, dateString) => {
    setReqBody({
      ...reqBody,
      deceasedDate: dateString ? dateString : "",
    });
    const deceasedDate = dateString ? dayjs(dateString) : null;
    const startDate = reqBody.startDate ? dayjs(reqBody.startDate) : null;

    if (startDate && deceasedDate && deceasedDate.isBefore(startDate)) {
      setPageError({
        ...pageError,
        deceasedDate: "Deceased date cannot be before start date",
      });
    } else {
      setPageError({
        ...pageError,
        deceasedDate: "",
      });
    }
  };
  const onDischargeDispositionChange = (e) => {
    setDischargeDisposition(e);
    setReqBody({ ...reqBody, dischargeDisposition: e ? e.value : "" });
    setPageError({
      ...pageError,
      dischargeDisposition: e ? "" : "Required",
    });
    if (e?.value !== "Other") {
      setReqBody({
        ...reqBody,
        dischargeDisposition: e ? e.value : "",
        dischargeDispositionOtherNotes: "",
      });
    }
  };

  const onSNFPartChange = (e) => {
    console.log("e", e);
    setReqBody((prevState) => ({
      ...prevState,
      partA: e?.find((item) => item.value === "snfa") ? "Yes" : "No",
      partB: e?.find((item) => item.value === "snfb") ? "Yes" : "No",
    }));
    setSNFPart(e);
    // setPageError({
    //   ...pageError,
    //   partA: e?.find((item) => item.value === "snfa") ? "" : "Required",
    //   partB: e?.find((item) => item.value === "snfb") ? "" : "Required",
    // });
  };

  const onSNFServiceChange = (e) => {
    setSNFServices(e);
    setReqBody({
      ...reqBody,
      snfServices: e ? e.map((item) => item.value).join(",") : "",
    });
    let temp = e?.filter((i) => {
      return i.value === "Other";
    });

    if (!temp[0]) {
      setReqBody({
        ...reqBody,
        snfServices: e ? e.map((item) => item.value).join(",") : "",
        snfServicesOtherNotes: "",
      });
    }
  };

  const handleInputChange = (name, value) => {
    setReqBody({
      ...reqBody,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const onSubmit = (e) => {
    setPageError({});
    let pageErrorTemp = {};
    const startDate = reqBody.startDate ? dayjs(reqBody.startDate) : null;
    const endDate = reqBody.endDate ? dayjs(reqBody.endDate) : null;
    const deceasedDate = reqBody.deceasedDate
      ? dayjs(reqBody.deceasedDate)
      : null;

    if (
      !reqBody.notificationSource ||
      (reqBody.notificationSource === "Other" &&
        !reqBody.notificationSourceOtherNotes) ||
      (reqBody.dischargeDisposition === "Other" &&
        !reqBody.dischargeDispositionOtherNotes) ||
      !reqBody.startDate ||
      !reqBody.admittingPhysician ||
      !reqBody.reasonAdmittingDx ||
      (reqBody.snfServices.includes("Other") && !reqBody.snfServicesOtherNotes)
    ) {
      pageErrorTemp.notificationSource = reqBody.notificationSource
        ? ""
        : "Required";
      pageErrorTemp.admittingPhysician = reqBody.admittingPhysician
        ? ""
        : "Required";
      pageErrorTemp.reasonAdmittingDx = reqBody.reasonAdmittingDx
        ? ""
        : "Required";
      pageErrorTemp.snfStartDate = reqBody.startDate ? "" : "Required";

      if (reqBody.notificationSource === "Other") {
        pageErrorTemp.notificationSourceOtherNotes =
          reqBody.notificationSourceOtherNotes ? "" : "Required";
      }
      if (reqBody.dischargeDisposition === "Other") {
        pageErrorTemp.dischargeDispositionOtherNotes =
          reqBody.dischargeDispositionOtherNotes ? "" : "Required";
      }
      if (reqBody.snfServices.includes("Other")) {
        pageErrorTemp.snfServicesOtherNotes = reqBody.snfServicesOtherNotes
          ? ""
          : "Required";
      }
    }

    if (startDate && endDate && endDate.isBefore(startDate)) {
      pageErrorTemp.endDate = "End Date cannot be before start date";
    }
    if (startDate && deceasedDate && deceasedDate.isBefore(startDate)) {
      pageErrorTemp.deceasedDate = "Deceased date cannot be before start date";
    }
    setPageError(pageErrorTemp);

    if (Object.keys(pageErrorTemp).length === 0) {
      onSave();
    }
  };

  const onSave = async () => {
    setSpinner(true);
    try {
      const response = await TocApi.postSnf(reqBody);
      setSpinner(false);
      callback(response);
      if (!editRecord.guid) {
        setSelectedSon("");
        setSNFEndDate("");
        setDeceasedDate("");
        setDischargeDisposition("");
        setSNFPart([]);
        setSNFServices([]);
        setReqBody(editRecord);
        if (response?.data?.mbi === reqBody?.mbi) {
          openNotification(
            "error",
            "Error",
            "There is a current SNF without a End Date or Deceased Date. You cannot create a new record without closing the current one."
          );
        } else if (response?.status === 200) {
          openNotification("success", "Added", "SNF Added Successfully!");
        } else {
          openNotification("error", "Error", "SNF failed to Add.");
        }
      } else {
        openNotification("success", "Updated", "SNF Updated Successfully!");
      }
    } catch (e) {
      console.log("e", e);
    }
  };

  console.log("reqBody", reqBody);

  return (
    <div className="tw-relative">
      {spinner && <MyBackdropSpinner show={spinner} />}

      <div className=" pb-4">
        <div className="form-group">
          <label htmlFor="selectedOption" className="fw-bold">
            Source of Notification
          </label>
          <MyCustomSelect
            selectOptions={sourceOfNotificationOptions}
            selectedValue={selectedSon}
            myCallBack={onSonChange}
            errorMessage={pageError?.notificationSource}
          />
        </div>
      </div>
      {selectedSon?.value === "Other" && (
        <div className=" pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"Source of Notification Other"}
              value={reqBody?.notificationSourceOtherNotes}
              setValue={(e) =>
                handleInputChange(
                  "notificationSourceOtherNotes",
                  e.target.value
                )
              }
              //setValue={onOtherSonChange}
              errorMessage={pageError?.notificationSourceOtherNotes}
            />
          </div>
        </div>
      )}
      <div className=" pb-4">
        <div className="form-group">
          <MyTextBox
            maxLength={100}
            label={"Admitting Physician"}
            value={reqBody?.admittingPhysician}
            setValue={(e) =>
              handleInputChange("admittingPhysician", e.target.value)
            }
            errorMessage={pageError?.admittingPhysician}
          />
        </div>
      </div>
      <div className=" pb-4">
        <div className="form-group">
          <MyTextArea
            maxLength={1000}
            rows={4}
            label={"Reason/Admitting Dx"}
            value={reqBody?.reasonAdmittingDx}
            setValue={(e) => handleInputChange("reasonAdmittingDx", e)}
            errorMessage={pageError?.reasonAdmittingDx}
          />
        </div>
      </div>
      <div className=" pb-4">
        <div className="form-group fw-bold">
          <label>SNF Start Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            value={reqBody.startDate ? dayjs(reqBody.startDate) : ""}
            onChange={onSNFStartdateChange}
            style={{ borderColor: pageError?.snfStartDate ? "red" : "#ddd" }}
          />
        </div>
      </div>
      <div className=" pb-4">
        <div className="form-group fw-bold">
          <label>SNF End Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            value={reqBody.endDate ? dayjs(reqBody.endDate) : ""}
            onChange={onSNFEnddateChange}
            style={{ borderColor: pageError?.endDate ? "red" : "#ddd" }}
          />
        </div>
        {pageError?.endDate && (
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.endDate}
          </span>
        )}
      </div>
      <div className=" pb-4">
        <div className="form-group fw-bold">
          <label>Deceased Date</label>
          <DatePicker
            className="form-control"
            placeholder="YYYY-MM-DD"
            format={"YYYY-MM-DD"}
            onChange={onDeceasedDate}
            value={reqBody.deceasedDate ? dayjs(reqBody.deceasedDate) : ""}
            style={{ borderColor: pageError?.deceasedDate ? "red" : "#ddd" }}
          />
        </div>
        {pageError?.deceasedDate && (
          <span
            className="text-danger"
            style={{ fontWeight: "normal", paddingLeft: "5px" }}
          >
            {pageError?.deceasedDate}
          </span>
        )}
      </div>
      <div className=" pb-4">
        <div className="form-group">
          <label htmlFor="dischargeDis" className="fw-bold">
            Discharge Disposition
          </label>
          <MyCustomSelect
            selectOptions={dischargeDispositionOptions}
            selectedValue={dischargeDisposition}
            myCallBack={onDischargeDispositionChange}
            //errorMessage={pageError?.dischargeDisposition}
          />
        </div>
      </div>
      {/* {dischargeDisposition?.find((i) => i.value === "Other") && ( */}
      {dischargeDisposition?.value === "Other" && (
        <div className=" pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"Discharge Disposition Other"}
              value={reqBody?.dischargeDispositionOtherNotes}
              setValue={(e) =>
                handleInputChange(
                  "dischargeDispositionOtherNotes",
                  e.target.value
                )
              }
              errorMessage={pageError?.dischargeDispositionOtherNotes}
            />
          </div>
        </div>
      )}

      <div className="form-group">
        <label htmlFor="loc" className="fw-bold">
          SNF ID
        </label>

        <MyCustomSelect
          mode="true"
          selectOptions={[
            { label: "Skilled Part A", value: "snfa" },
            { label: "Skilled Part B", value: "snfb" },
          ]}
          myCallBack={onSNFPartChange}
          selectedValue={snfPart}
          errorMessage={pageError?.partA || pageError?.partB}
        />
      </div>

      <div className="tw-py-4 ">
        <div className="form-group">
          <label htmlFor="loc" className="fw-bold">
            SNF Services
          </label>
          <MyCustomSelect
            mode="true"
            selectOptions={snfServiceOptions}
            myCallBack={onSNFServiceChange}
            selectedValue={snfServices}
          />
        </div>
      </div>
      {snfServices?.find((i) => i.value === "Other") && (
        <div className=" pb-4">
          <div className="form-group">
            <MyTextBox
              maxLength={100}
              label={"SNF Service Other"}
              value={reqBody?.snfServicesOtherNotes}
              setValue={(e) =>
                handleInputChange("snfServicesOtherNotes", e.target.value)
              }
              errorMessage={pageError?.snfServicesOtherNotes}
            />
          </div>
        </div>
      )}
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className="btn btn-success"
          htmlFor="save"
          name={"Save"}
          type="submit"
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          htmlFor="close"
          name={"Close"}
          onClick={() => callback(true)}
        >
          {"Close"}
        </button>
      </div>
    </div>
  );
};
