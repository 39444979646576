import React, { useEffect, useState } from "react";
import Medications from "../Medications/Medications";
import Conditions from "../Conditions/Conditions";
import TOC from "../TOC/TOC";
import { Collapse, Typography, DatePicker, Select } from "antd";
import ADT from "../ADT/ADT";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { RiAlignBottom, RiCake2Line, RiMapPinLine } from "react-icons/ri";
import { Space } from "antd";
import { IoArrowBackCircle } from "react-icons/io5";
import PageTitle from "../Common/PageTitle";
import {
  getPatientInfo,
  getPatientInfoFromMbi,
} from "../../api/PatientInfoApi";
import { FaHospitalUser } from "react-icons/fa";
import * as HRRPatientapi from "../../api/HRRPatientsApi";
import dayjs from "dayjs";
//import { DatePicker } from "antd";
//import RAF from "../RAF/RAF";
import AdvanceDirectives from "../AdvanceDirectives/AdvanceDirectives";
import MyBackdropSpinner from "../../common/elements/myBackdropSpinner";
import HCC from "../HCC/Hcc";
import TOCTimeLine from "../TOC/TOCTimeLine";

const getPatientSourceBadgeColors = (source) => {
  if (source === "HN") {
    return "tw-bg-green-100 tw-text-green-700";
  } else if (source === "SCP") {
    return "tw-bg-blue-100 tw-text-blue-700";
  } else if (source === "PAC") {
    return "tw-bg-red-100 tw-text-red-700";
    // } else if (source === "MxC") {
    //   return "tw-bg-purple-100 tw-text-purple-700";
  }
};

const Pt360 = () => {
  const [patientInfoData, setPatientInfoData] = useState();
  const [day, setDay] = useState(0);
  const [spinner, setSpinner] = useState(false);
  const params = useParams();
  const navigate = useNavigate();

  const [editPOLSTADDate, setEditPOLSTADDate] = useState(false);
  const [selectedPOLSTADDate, setSelectedPOLSTADDate] = useState(null);
  const [editCCEDate, setEditCCEDate] = useState(false);
  const [selectedCCEDate, setSelectedCCEDate] = useState(null);
  const [editCapacity, setEditCapacity] = useState(false);
  const [selectedCapacity, setSelectedCapacity] = useState(false);
  const [editPalliativeCare, setEditPalliativeCare] = useState(false);
  const [selectedPalliativeCare, setSelectedPalliativeCare] = useState(false);
  const [editHospiceCare, setEditHospiceCare] = useState(false);
  const [selectedHospiceCare, setSelectedHospiceCare] = useState(false);
  const [selectedPatientId, setPatientId] = useState(0);

  useEffect(() => {
    setSpinner(true);
    console.log(params);
    const patientInfo = params?.patientId
      ? getPatientInfo(params?.patientId)
      : getPatientInfoFromMbi(params?.mbiid);

    Promise.all([patientInfo]).then((values) => {
      setPatientInfoData(values[0].data);
      setPatientId(
        values[0].data?.patientId == null ? 0 : values[0].data?.patientId
      );
      setSpinner(false);
    });
  }, [day]);

  let postData = {
    plotsADLastUpdatedDate:
      selectedPOLSTADDate || patientInfoData?.poltsADUpdatedDate,
    mbiid: patientInfoData?.mbi,
    cceLastUpdatedDate: selectedCCEDate || patientInfoData?.cceUpdatedDate,
    isPalliativeCare:
      selectedPalliativeCare || patientInfoData?.isPalliativeCare,
    isHospiceCare: selectedHospiceCare || patientInfoData?.isHospiceCare,
    capcityID: selectedCapacity || patientInfoData?.capacity,
  };

  const onSaveCCE = async (cceDateVal) => {
    setSpinner(true);
    try {
      postData.cceLastUpdatedDate = cceDateVal;
      const response = await HRRPatientapi.patient360(postData);
      console.log("response", response);
      setSpinner(false);
    } catch (e) {
      console.log("e", e);
    }
  };

  const onSavePoltsDate = async (polstDateVal) => {
    setSpinner(true);
    try {
      postData.plotsADLastUpdatedDate = polstDateVal;
      const response = await HRRPatientapi.patient360(postData);
      console.log("response", response);
      setSpinner(false);
    } catch (e) {
      console.log("e", e);
    }
  };

  const onSaveCapacity = async (capacity) => {
    setSpinner(true);
    try {
      postData.capcityID = capacity;
      const response = await HRRPatientapi.patient360(postData);
      console.log("response", response);
      setSpinner(false);
    } catch (e) {
      console.log("e", e);
    }
  };

  const onSavePalliativeCare = async (palliativeCare) => {
    setSpinner(true);
    try {
      postData.isPalliativeCare = palliativeCare;
      const response = await HRRPatientapi.patient360(postData);
      console.log("response", response);
      setSpinner(false);
    } catch (e) {
      console.log("e", e);
    }
  };

  const onSaveHospiceCare = async (hospiceCare) => {
    setSpinner(true);
    try {
      postData.isHospiceCare = hospiceCare;
      const response = await HRRPatientapi.patient360(postData);
      console.log("response", response);
      setSpinner(false);
    } catch (e) {
      console.log("e", e);
    }
  };

  const items = [
    {
      key: "adv",
      label: "Advance Directives",
      children: (
        <div>
          <AdvanceDirectives patientId={selectedPatientId} />
        </div>
      ),
    },
    {
      key: "med",
      label: "Medications",
      children: (
        <div>
          <Medications patientId={selectedPatientId} />
        </div>
      ),
    },
    {
      key: "cond",
      label: "Conditions",
      children: (
        <div>
          <Conditions patientId={selectedPatientId} />
        </div>
      ),
    },
    {
      key: "adt",
      label: "ADT",
      children: (
        <div>
          <ADT patientId={selectedPatientId} />
        </div>
      ),
    },
    {
      key: "toc",
      label: "TOC - CCC",
      children: (
        <div>
          <TOC mbi={patientInfoData?.mbi} />
        </div>
      ),
    },
    {
      key: "toctl",
      label: "TOC - CCC - TimeLine",
      children: (
        <div>
          <TOCTimeLine mbi={patientInfoData?.mbi} />
        </div>
      ),
    },

    // {
    //   key: "raf",
    //   label: "RAF",
    //   children: (
    //     <div>
    //       <RAF patientId={params?.patientId} />
    //     </div>
    //   ),
    // },
    // {
    //   key: "hcc",
    //   label: "HCC",
    //   children: (
    //     <div>
    //       <HCC patientId={params?.patientId} />
    //     </div>
    //   ),
    // },
  ];

  return (
    <>
      <Space style={{ marginBottom: 8 }} align="start">
        <IoArrowBackCircle size="2em" onClick={() => navigate(-1)} />
        <PageTitle title={``} />
      </Space>

      {spinner && <MyBackdropSpinner show={spinner} />}
      <div className="tw-text-gray-700 tw-p-4 border tw-rounded-md tw-flex tw-space-x-8">
        <div className="tw-w-1/5 tw-font-medium tw-bg-gray-100 -tw-m-4 tw-p-4 tw-rounded-l-[5px]">
          <div className="tw-capitalize tw-text-2xl tw-text-gray-800">
            {/* To avoid showing undefined, undefined initially  */}
            {patientInfoData?.lastName}
            {patientInfoData?.lastName == null ? " " : ",  "}
            {patientInfoData?.firstName}
          </div>
          <div>{patientInfoData?.mbi}</div>

          <div className="tw-flex tw-space-x-2">
            <div
              className={`tw-py-0.5 tw-px-2 tw-text-xs tw-rounded tw-mt-1 ${getPatientSourceBadgeColors(
                patientInfoData?.lob
              )}`}
            >
              {patientInfoData?.lob}
            </div>
          </div>
          <div className="tw-mt-4">
            <div className="tw-flex tw-items-center tw-space-x-2">
              <FaRegUser size={18} className="tw-text-gray-400" />
              <div>{patientInfoData?.gender}</div>
            </div>
            <div className="tw-mt-2 tw-flex tw-items-center tw-space-x-2">
              <RiCake2Line size={18} className="tw-text-gray-400" />
              <div>
                {dayjs(patientInfoData?.dateOfBirth).utc().format("YYYY-MM-DD")}
              </div>
            </div>
            <div className="tw-ml-[26px]">{patientInfoData?.age} years</div>
            <div className="tw-mt-2 tw-flex tw-items-center tw-space-x-2">
              <RiMapPinLine size={18} className="tw-text-gray-400" />
              <div>{patientInfoData?.snfState}</div>
            </div>
            <div className="tw-mt-2 tw-flex tw-items-center tw-space-x-2">
              <FaHospitalUser size={18} className="tw-text-gray-400" />
              <div>{patientInfoData?.pyStatus}</div>
            </div>
          </div>
        </div>

        <div className="tw-space-y-6 tw-w-full">
          <div className="tw-flex tw-space-x-28 tw-text-gray-800">
            <div className="tw-flex tw-space-x-10">
              <div className="tw-space-y-1.5">
                <div className="tw-text-gray-500">CCMD RAF</div>
                <div className="tw-text-gray-500">EMR MRN</div>
                {/* <div className="tw-text-gray-500">CMS High Risk</div>
                <div className="tw-text-gray-500">EMR High Risk</div> */}
                <div className="tw-text-gray-500">CMS Race/Ethnicity</div>
                <div className="tw-text-gray-500">Preferred Language</div>
                <div className="tw-text-gray-500">CCMD Provider</div>
                <div className="tw-text-gray-500">CMS Aligned Provider</div>
                <div className="tw-text-gray-500">SNF Location</div>
              </div>

              <div className="tw-space-y-1.5">
                <div className="tw-font-medium">NA</div>
                <div className="tw-font-medium">
                  {patientInfoData?.medicalRecordNumber
                    ? patientInfoData?.medicalRecordNumber
                    : "NA"}
                </div>
                {/* <div className="tw-font-medium">NA</div>
                <div className="tw-font-medium">NA</div> */}
                <div className="tw-font-medium">
                  {patientInfoData?.ethnicity}
                </div>
                <div className="tw-font-medium">
                  {patientInfoData?.preferredLang}
                </div>
                <div className="tw-font-medium">
                  {patientInfoData?.assignedProvider}
                </div>
                <div className="tw-font-medium">
                  {patientInfoData?.alignedProvider}
                </div>
                <div className="tw-font-medium">
                  {patientInfoData?.snfFacilityName}
                </div>
              </div>
            </div>

            <div className="tw-flex tw-space-x-10">
              <div className="tw-space-y-1.5">
                <div className="tw-text-gray-500">First Alignment Date</div>
                {/* {patientInfoData?.alignmentType && ( */}
                <div className="tw-text-gray-500">Alignment Type</div>
                {/* )} */}
                {/* <div className="tw-text-gray-500">PY Status</div> */}
                {patientInfoData?.deceasedDate && (
                  <div className="tw-text-gray-500">Deceased Date</div>
                )}
                <div className="tw-text-gray-500">POLST/AD Last Update</div>
                <div className="tw-text-gray-500">Last Completed CCE</div>
                <div className="tw-text-gray-500">Capacity</div>
                <div className="tw-text-gray-500">Palliative Care</div>
                <div className="tw-text-gray-500">Hospice Care</div>
              </div>

              <div className="tw-space-y-1.5">
                <div className="tw-font-medium">
                  {patientInfoData?.firstAlignmentDate
                    ? dayjs(patientInfoData?.firstAlignmentDate)
                        .utc()
                        .format("YYYY-MM-DD")
                    : "NA"}
                </div>
                <div className="tw-font-medium">
                  {patientInfoData?.alignmentType
                    ? patientInfoData?.alignmentType
                    : "NA"}
                </div>
                {/* <div className="tw-font-medium">
                  {patientInfoData?.pyStatus}
                </div> */}
                <div className="tw-font-medium">
                  {" "}
                  {patientInfoData?.deceasedDate
                    ? dayjs(patientInfoData.deceasedDate)
                        .utc()
                        .format("YYYY-MM-DD")
                    : ""}
                </div>
                <div className="tw-font-medium tw-flex tw-space-x-3 tw-items-baseline">
                  {editPOLSTADDate ? (
                    <>
                      <DatePicker
                        onOpenChange={(open) => setEditPOLSTADDate(open)}
                        open={editPOLSTADDate}
                        size="small"
                        onChange={(date) => {
                          setSelectedPOLSTADDate(date.format("YYYY-MM-DD"));
                          setEditPOLSTADDate(false);
                          onSavePoltsDate(dayjs(date).format("YYYY-MM-DD"));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <div>
                        {selectedPOLSTADDate ||
                        patientInfoData?.poltsADUpdatedDate
                          ? selectedPOLSTADDate ||
                            dayjs(patientInfoData?.poltsADUpdatedDate)
                              .utc()
                              .format("YYYY-MM-DD")
                          : "NA"}
                      </div>
                      <Typography.Link
                        onClick={() => {
                          setEditPOLSTADDate(true);
                        }}
                      >
                        Edit
                      </Typography.Link>
                    </>
                  )}
                </div>
                <div className="tw-font-medium tw-flex tw-space-x-3 tw-items-baseline">
                  {editCCEDate ? (
                    <>
                      <DatePicker
                        onOpenChange={(open) => setEditCCEDate(open)}
                        open={editCCEDate}
                        size="small"
                        onChange={(date) => {
                          setSelectedCCEDate(date.format("YYYY-MM-DD"));
                          onSaveCCE(dayjs(date).format("YYYY-MM-DD"));
                          setEditCCEDate(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <div>
                        {selectedCCEDate || patientInfoData?.cceUpdatedDate
                          ? selectedCCEDate ||
                            dayjs(patientInfoData?.cceUpdatedDate)
                              .utc()
                              .format("YYYY-MM-DD")
                          : "NA"}
                      </div>
                      <Typography.Link
                        onClick={() => {
                          setEditCCEDate(true);
                        }}
                      >
                        Edit
                      </Typography.Link>
                    </>
                  )}
                </div>
                <div className="tw-font-medium tw-flex tw-space-x-3 tw-items-baseline">
                  {editCapacity ? (
                    <>
                      <Select
                        className="tw-flex-grow"
                        onDropdownVisibleChange={(open) =>
                          setEditCapacity(open)
                        }
                        open={editCapacity}
                        size="small"
                        options={[
                          { value: "Tier 1", label: "Tier 1" },
                          { value: "Tier 2", label: "Tier 2" },
                          { value: "Tier 3", label: "Tier 3" },
                        ]}
                        onChange={(option) => {
                          setSelectedCapacity(option);
                          onSaveCapacity(option);
                          setEditCapacity(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <div className="tw-capitalize">
                        {selectedCapacity || patientInfoData?.capacity}
                      </div>
                      <Typography.Link
                        onClick={() => {
                          setEditCapacity(true);
                        }}
                      >
                        Edit
                      </Typography.Link>
                    </>
                  )}
                </div>
                <div className="tw-font-medium tw-flex tw-space-x-3 tw-items-baseline">
                  {editPalliativeCare ? (
                    <>
                      <Select
                        className="tw-flex-grow"
                        onDropdownVisibleChange={(open) =>
                          setEditPalliativeCare(open)
                        }
                        open={editPalliativeCare}
                        size="small"
                        options={[
                          { value: "Y", label: "Y" },
                          { value: "N", label: "N" },
                        ]}
                        onChange={(option) => {
                          setSelectedPalliativeCare(option);
                          setEditPalliativeCare(false);
                          onSavePalliativeCare(option);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <div className="tw-capitalize">
                        {selectedPalliativeCare ||
                          patientInfoData?.isPalliativeCare?.trim()}
                      </div>
                      <Typography.Link
                        onClick={() => {
                          setEditPalliativeCare(true);
                        }}
                      >
                        Edit
                      </Typography.Link>
                    </>
                  )}
                </div>
                <div className="tw-font-medium tw-flex tw-space-x-3 tw-items-baseline">
                  {editHospiceCare ? (
                    <>
                      <Select
                        className="tw-flex-grow"
                        onDropdownVisibleChange={(open) =>
                          setEditHospiceCare(open)
                        }
                        open={editHospiceCare}
                        size="small"
                        options={[
                          { value: "Y", label: "Y" },
                          { value: "N", label: "N" },
                        ]}
                        onChange={(option) => {
                          setSelectedHospiceCare(option);
                          onSaveHospiceCare(option);
                          setEditHospiceCare(false);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <div className="tw-capitalize">
                        {selectedHospiceCare ||
                          patientInfoData?.isHospiceCare?.trim()}
                      </div>
                      <Typography.Link
                        onClick={() => {
                          setEditHospiceCare(true);
                        }}
                      >
                        Edit
                      </Typography.Link>
                    </>
                  )}
                </div>
                {/* <div className="tw-font-medium">
                  {dayjs(patientInfoData?.poltsADUpdatedDate).format(
                    "YYYY-MM-DD"
                  )}{" "}
                </div>
                <div className="tw-font-medium">
                  {dayjs(patientInfoData?.cceUpdatedDate).format("YYYY-MM-DD")}
                </div>
                <div className="tw-font-medium">
                  {patientInfoData?.capacity}
                </div>
                <div className="tw-font-medium">
                  {patientInfoData?.isPalliativeCare?.trim()}
                </div>
                <div className="tw-font-medium">
                  {patientInfoData?.isHospiceCare?.trim()}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Collapse
          defaultActiveKey={["1"]}
          expandIconPosition="end"
          className="tw-mt-6"
        >
          {items.map((item) => (
            <Collapse.Panel
              header={<span className=" tw-font-bold">{item.label}</span>}
              key={item.key}
            >
              {item.children}
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </>
  );
};

export default Pt360;
