import React, { useState } from "react";
import MyTextBox from "../../common/elements/myTextbox";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import { addHospitalAdmission } from "../../api/CareEventSCPApi";
import { openNotification } from "../../common/elements/myNotification";

const CareHospitalAdmissionAdd = ({ editRecord, callback }) => {
  const [formData, setFormData] = useState(
    editRecord?.guid
      ? {
          ...editRecord,
          modifiedBy: sessionStorage.getItem("userName"),
        }
      : editRecord
  );
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isMDPhoneValid, setIsMDPhoneValid] = useState(true);
  const [isCMPhoneValid, setIsCMPhoneValid] = useState(true);
  const [pageError, setPageError] = useState({});
  const [disable, setDisable] = useState(false);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
    setPageError({ ...pageError, [name]: value ? "" : "Required" });
  };

  const handleDateChange = (fieldName, dateString) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: dateString ? dateString : "",
    }));
    setPageError((prevErrors) => ({
      ...prevErrors,
      [fieldName]: dateString ? "" : "Required",
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    setPageError({}); // Clear previous error messages
    let pageErrorTemp = {}; // Initialize a temporary error object

    if (!formData?.hospitalName) {
      pageErrorTemp.hospitalName = "Required";
    }

    if (!formData?.hospitalTelephone) {
      pageErrorTemp.hospitalTelephone = "Required";
    } else if (!isPhoneValid) {
      pageErrorTemp.hospitalTelephone = "Invalid phone number";
    }

    if (!formData?.hospitalAdmissionDate) {
      pageErrorTemp.hospitalAdmissionDate = "Required";
    }

    if (!formData?.hospitalAdmittingSymptoms) {
      pageErrorTemp.hospitalAdmittingSymptoms = "Required";
    }

    if (formData?.hospitalAttendingMDContact && !isMDPhoneValid) {
      pageErrorTemp.hospitalAttendingMDContact = "Required";
    }
    if (formData?.hospitalCaseManagerContact && !isCMPhoneValid) {
      pageErrorTemp.hospitalCaseManagerContact = "Required";
    }

    if (Object.keys(pageErrorTemp).length > 0) {
      setPageError(pageErrorTemp);
      return;
    }

    setDisable(true);
    onSave(e);
  };

  const onSave = async (e) => {
    try {
      const response = await addHospitalAdmission(formData);
      if (response?.status === 200 && !formData?.guid) {
        openNotification(
          "success",
          "Added",
          "Hospital Admission Template Added Successfully!"
        );
      } else if (formData?.guid) {
        openNotification(
          "success",
          "Updated",
          "Hospital Admission Template Updated Successfully!"
        );
      } else {
        openNotification(
          "error",
          "Error",
          "Hospital Admission Template Failed to Add!"
        );
      }
      console.log("response", response);
      setFormData(editRecord);
      setDisable(false);
      callback(response);
    } catch (e) {
      return e;
    }
  };

  return (
    <div className="tw-relative tw-px-2">
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"Hospital Name"}
          value={formData?.hospitalName}
          errorMessage={pageError?.hospitalName}
          setValue={(e) => handleInputChange("hospitalName", e.target.value)}
          maxLength={100}
        />
      </div>
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"Hospital Telephone#"}
          value={formData?.hospitalTelephone}
          errorMessage={pageError?.hospitalTelephone}
          setValue={(e) =>
            handleInputChange("hospitalTelephone", e.target.value)
          }
          isPhoneNumber={true}
          onValidChange={(e) => setIsPhoneValid(e)}
        />
      </div>
      <div className="col-md-11 col-lg-11 form-group tw-mr-2">
        <div className="tw-flex">
          <label className="tw-font-bold" htmlFor="dateTime">
            Hospital Admission Date
          </label>
        </div>
        <DatePicker
          use12Hours
          className="form-control tw-mb-3"
          onChange={(date, dateString) =>
            handleDateChange("hospitalAdmissionDate", dateString)
          }
          format={"YYYY-MM-DD"}
          value={
            formData?.hospitalAdmissionDate
              ? dayjs(formData.hospitalAdmissionDate)
              : ""
          }
          style={{
            border: pageError?.hospitalAdmissionDate ? "1px solid red" : "",
          }}
        />
      </div>
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"Hospital Admitting Symptoms / Dx"}
          value={formData?.hospitalAdmittingSymptoms}
          errorMessage={pageError?.hospitalAdmittingSymptoms}
          setValue={(e) =>
            handleInputChange("hospitalAdmittingSymptoms", e.target.value)
          }
          maxLength={100}
        />
      </div>
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"Hospital Attending MD Name"}
          value={formData?.hospitalAttendingMDName}
          setValue={(e) =>
            handleInputChange("hospitalAttendingMDName", e.target.value)
          }
          maxLength={100}
        />
      </div>
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"Hospital Attending MD Contact#"}
          value={formData?.hospitalAttendingMDContact}
          setValue={(e) =>
            handleInputChange("hospitalAttendingMDContact", e.target.value)
          }
          isPhoneNumber={true}
          onValidChange={(e) => setIsMDPhoneValid(e)}
        />
      </div>
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"Hospital Case Manager Name"}
          value={formData?.hospitalCaseManagerName}
          setValue={(e) =>
            handleInputChange("hospitalCaseManagerName", e.target.value)
          }
          maxLength={100}
        />
      </div>
      <div className="form-group tw-mb-3 tw-mr-16 tw-pr-2">
        <MyTextBox
          label={"Hospital Case Manager Contact#"}
          value={formData?.hospitalCaseManagerContact}
          setValue={(e) =>
            handleInputChange("hospitalCaseManagerContact", e.target.value)
          }
          isPhoneNumber={true}
          onValidChange={(e) => setIsCMPhoneValid(e)}
        />
      </div>
      <div className="form-group tw-mr-16 tw-pr-2">
        <label htmlFor="selectedOption" className="fw-bold">
          MedEx Notes / Message
        </label>
        <TextArea
          className="form-control"
          maxLength={"1000"}
          value={formData?.medxNotes}
          onChange={(e) => handleInputChange("medxNotes", e.target.value)}
        />
      </div>
      <div className="form-group tw-fixed tw-top-2 tw-right-10">
        <button
          className={!disable ? "btn btn-success" : "btn btn-success disabled"}
          onClick={onSubmit}
        >
          {editRecord?.guid ? "Update" : "Save"}
        </button>
        <button
          className="btn btn-secondary tw-ml-4"
          onClick={() => callback(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default CareHospitalAdmissionAdd;
