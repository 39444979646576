const TuckInCallColumns = [
  {
    title: "Location",
    dataIndex: "location",
  },
  {
    title: "Level of Care",
    dataIndex: "levelOfCareLabel",
  },
  {
    title: "POLST/AD/CodeStatus on File",
    dataIndex: "codeStatusOnFile",
  },
  {
    title: "Are there any new medication orders?",
    dataIndex: "isThereNewMedication",
  },
  {
    title: "Have medications arrived to the patient?",
    dataIndex: "isMedicationArrived",
  },
  {
    title: "Does the patient have a follow-up appointment with the PCP?",
    dataIndex: "isNCPFollowUpCallSetUpDone",
  },
  {
    title: "Nurse Summary Note",
    dataIndex: "nurseSummaryNote",
  },
  {
    title: "Follow-up Date & Time",
    dataIndex: "followupDateTime",
  },
];

const TherapyColumns = [
  {
    title: "Medicare A/B",
    dataIndex: "medicareA",
  },
  {
    title: "Has patient met maximum potential?",
    dataIndex: "hasPatientMetMaximumPotential",
  },
  {
    title: "Goals of Therapy",
    dataIndex: "therapyGoals",
  },
  {
    title: "Therapy Services and frequency",
    dataIndex: "therapyDetails",
  },
  {
    title: "Case Manager Recommendations",
    dataIndex: "caseManagerRecommendations",
  },
  {
    title: "Review Date",
    dataIndex: "reviewDate",
  },
];

const ChangeinConditionColumns = [
  {
    title: "Type of Exacerbation",
    dataIndex: "acuteOrChronic",
  },
  {
    title: "Change in Condition",
    dataIndex: "changeCondition",
  },
  {
    title: "Course/Plan of Treatment",
    dataIndex: "patientPlan",
  },
  {
    title: "Note Date & Time",
    dataIndex: "noteDateTime",
  },
];

const NotesColumns = [
  {
    title: "Note Type",
    dataIndex: "noteLabel",
  },
  {
    title: "Note",
    dataIndex: "note",
  },
  {
    title: "Note Date & Time",
    dataIndex: "noteDateTime",
  },
];

const IPHospitalCommColumns = [
  {
    title: "Hospital Name",
    dataIndex: "hospitalName",
  },
  {
    title: "Admission Date",
    dataIndex: "dateOfAdmission",
  },
  {
    title: "Attending Physician",
    dataIndex: "attendingPhysician",
  },
  {
    title: "Care/Case Manager",
    dataIndex: "hospitalCareManager",
  },
  {
    title: "Admit Dx",
    dataIndex: "admittingDiagnosis",
  },
  {
    title: "Expected LOS",
    dataIndex: "expectedLengthOfStay",
  },
  {
    title: "Discharge Disposition",
    dataIndex: "dischargeDisposition",
  },
  {
    title: "Notes",
    dataIndex: "notes",
  },
  {
    title: "IP Note Date & Time",
    dataIndex: "iPCommunicationNoteDateTime",
  },
];

const AdmReAdmColumns = [
  {
    title: "Admission / Re-Admission",
    dataIndex: "admissionType",
  },
  {
    title: "Date",
    dataIndex: "dateOfAdmission",
  },
  {
    title: "Skilled / Custodial",
    dataIndex: "skilledOrCustodial",
  },
  {
    title: "Patient Home Address",
    dataIndex: "patientLocation",
  },
  {
    title: "Facility",
    dataIndex: "facilityName",
  },
  {
    title: "Room #",
    dataIndex: "roomNumber",
  },
  {
    title: "Health Plan / Medical Group",
    dataIndex: "healthPlan",
  },
  {
    title: "Policy Number",
    dataIndex: "policyNumber",
  },
  {
    title: "SSN",
    dataIndex: "ssn",
  },
  {
    title: "NP/PA",
    dataIndex: "nporpaLabel",
  },
  {
    title: "Provider",
    dataIndex: "providerNameLabel",
  },
  {
    title: "Caller Name",
    dataIndex: "callerName",
  },
  {
    title: "Caller Relationship to Patient",
    dataIndex: "callerRelationshipPatient",
  },
  {
    title: "Nurse Station",
    dataIndex: "nurseStation",
  },
  {
    title: "Call back number",
    dataIndex: "callBackNumber",
  },
  {
    title: "Message",
    dataIndex: "message",
  },
  {
    title: "Message sent to",
    dataIndex: "messageSentTo",
  },
  {
    title: "Attachment Links",
    dataIndex: "attachmentLinks",
  },
  {
    title: "Note Date & Time",
    dataIndex: "createdDateTime",
  },
];

const HighRiskColumns = [
  {
    title: "Assessment Date & Time",
    dataIndex: "assessmentDateTime",
  },
  {
    title: "Summary",
    dataIndex: "summary",
  },
  {
    title: "High Risk Criteria",
    dataIndex: "highRiskCriteria",
  },
  {
    title: "Was a CCE completed within this year",
    dataIndex: "isCCECompletedThisYear",
  },
  {
    title: "Cognition",
    dataIndex: "cognition",
  },
  {
    title: "Physician",
    dataIndex: "physician",
  },
  {
    title: "NP",
    dataIndex: "nP",
  },
  {
    title: "Specialist",
    dataIndex: "specialist",
  },
  {
    title: "Family",
    dataIndex: "family",
  },
  {
    title: "DPOA",
    dataIndex: "dPOA",
  },
  {
    title: "Email",
    dataIndex: "eMail",
  },
  {
    title: "Best Contact",
    dataIndex: "bestContact",
  },
  {
    title: "POLST",
    dataIndex: "pOlst",
  },
  {
    title: "Advanced Directives",
    dataIndex: "advancedDirectives",
  },
  {
    title: "Number Of Medications",
    dataIndex: "numberOfMedicationsLabel",
  },
  {
    title: "ED / Hospital Visit",
    dataIndex: "isErHospitalVisit",
  },
  {
    title: "ED / Hospital Visit Notes",
    dataIndex: "erHospitalVisitNotes",
  },
  {
    title: "Care Coordination Needs",
    dataIndex: "isCareCoordinationNeeds",
  },
  {
    title: "Care Coordination Needs Notes",
    dataIndex: "careCoordinationNeedsNotes",
  },
  {
    title: "Social Worker Needs",
    dataIndex: "isSocialWorkerNeeds",
  },
  {
    title: "Social Worker Needs Notes",
    dataIndex: "socialWorkerNeedsNotes",
  },
  {
    title: "Notes",
    dataIndex: "notes",
  },
];

const GrievanceColumns = [
  {
    title: "Caller Name / Caller Contact #",
    dataIndex: "callerName",
  },
  {
    title: "Message",
    dataIndex: "callerMessage",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const EDColumns = [
  {
    title: "ED Name/Telephone#",
    dataIndex: "erName",
  },
  {
    title: "Visit Date",
    dataIndex: "erVisitDate",
  },
  {
    title: "Visit Symptom/Dx",
    dataIndex: "erVisitSymptonDx",
  },
  {
    title: "Medex Notes/Message",
    dataIndex: "medxNotes",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const TierChangeColumns = [
  {
    title: "Provider Name",
    dataIndex: "providerName",
  },
  {
    title: "Tier Change",
    dataIndex: "tierChange",
  },
  {
    title: "Reason of Tier Change",
    dataIndex: "reasonForTierChange",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const OrderColumns = [
  {
    title: "Urgent",
    dataIndex: "urgent",
  },
  {
    title: "Allergy",
    dataIndex: "allergy",
  },
  {
    title: "Palliative Provider's Order",
    dataIndex: "palliativeProviderOrder",
  },
  {
    title: "Date of Order Submission",
    dataIndex: "dateOfOrderSubmoission",
  },
  {
    title: "Name of Ordering Palliative Provider",
    dataIndex: "palliativeProviderName",
  },
  {
    title: "Reason for Order",
    dataIndex: "reasonForOrder",
  },
  {
    title: "IPA",
    dataIndex: "IPA",
  },
  {
    title: "Servicing Provider",
    dataIndex: "serviceProvider",
  },
  {
    title: "Authorization Number",
    dataIndex: "authoriastionNo",
  },
  {
    title: "Order Status",
    dataIndex: "orderStatus",
  },
  {
    title: "Comments",
    dataIndex: "comments",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const DMEColumns = [
  {
    title: "Urgent",
    dataIndex: "urgent",
  },
  {
    title: "Name of DME Requested",
    dataIndex: "nameOfDMERequest",
  },
  {
    title: "Date of DME Request Submission",
    dataIndex: "dateOfDMESubmission",
  },
  {
    title: "Palliative Provider Requesting DME",
    dataIndex: "palliativeProviderRequestingDME",
  },
  {
    title: "Reason why DME is being Requested",
    dataIndex: "reasonForDMERequest",
  },
  {
    title: "IPA",
    dataIndex: "IPA",
  },
  {
    title: "Facility Name",
    dataIndex: "facilityName",
  },
  {
    title: "Servicing Provider",
    dataIndex: "serviceProvider",
  },
  {
    title: "Authorization Number",
    dataIndex: "authoriastionNo",
  },
  {
    title: "Request Status",
    dataIndex: "requestStatus",
  },
  {
    title: "Delivery Confirmation",
    dataIndex: "deliveryConfirmation",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const NurseColumns = [
  {
    title: "Name of Requesting Nurse",
    dataIndex: "requestingNurseName",
  },
  {
    title: "Date request was sent to team",
    dataIndex: "dateRequestSent",
  },
  {
    title: "Request Details",
    dataIndex: "requestungDetails",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const HospAdmColumns = [
  {
    title: "Hospital Name / Hospital Telephone#",
    dataIndex: "hospitalName",
  },
  {
    title: "Hospital Admission Date",
    dataIndex: "hospitalAdmissionDate",
  },
  {
    title: "Hospital Admitting Symptoms / Dx",
    dataIndex: "hospitalAdmittingSymptoms",
  },
  {
    title: "Hospital Attending MD Name / Contact#",
    dataIndex: "hospitalAttendingMDName",
  },
  {
    title: "Hospital Case Manager Name / Contact#",
    dataIndex: "hospitalCaseManagerName",
  },
  {
    title: "MedEx Notes / Message",
    dataIndex: "medxNotes",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const HospDischColumns = [
  {
    title: "Hospital Name / Hospital Telephone#",
    dataIndex: "hospitalName",
  },
  {
    title: "Hospital Admission Date",
    dataIndex: "hospitalAdmissionDate",
  },
  {
    title: "Hospital Discharge Date",
    dataIndex: "hospitalDischargeDate",
  },
  {
    title: "Hospital Discharge Dx",
    dataIndex: "hospitalDischargeDx",
  },
  {
    title: "Location Discharged To",
    dataIndex: "locationDischargedTo",
  },
  {
    title: "Discharge Instructions",
    dataIndex: "dischargeInstructions",
  },
  {
    title: "MedEx Notes / Message",
    dataIndex: "medxNotes",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const OutboundColumns = [
  {
    title: "NP / PA",
    dataIndex: "nporpaLabel",
  },
  {
    title: "Provider",
    dataIndex: "providerNameLabel",
  },
  {
    title: "Phone Number",
    dataIndex: "phoneNumbers",
  },
  {
    title: "Team",
    dataIndex: "team",
  },
  {
    title: "Change of Condition",
    dataIndex: "changeOfCondition",
  },
  {
    title: "Last Nurse Visit",
    dataIndex: "lastNurseVisit",
  },
  {
    title: "ER Visit / Hospitalization",
    dataIndex: "erVisitHospitaliastion",
  },
  {
    title: "Call Frequency",
    dataIndex: "callFrequencyValue",
  },
  {
    title: "Comments",
    dataIndex: "comments",
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
  },
];

const TriageColumns = [
  {
    title: "Call Type",
    dataIndex: "callType",
  },
  {
    title: "Is this urgent",
    dataIndex: "isThisUrgent",
  },
  {
    title: "Is this a HN Patient",
    dataIndex: "isThisHnPatient",
  },
  {
    title: "NP/PA",
    dataIndex: "nporpaLabel",
  },
  {
    title: "Provider",
    dataIndex: "providerNameLabel",
  },
  {
    title: "Facility",
    dataIndex: "facilityName",
  },
  {
    title: "Health Plan / Medical Group",
    dataIndex: "healthPlan",
  },
  {
    title: "Policy Number",
    dataIndex: "policyNumber",
  },
  {
    title: "Caller Name",
    dataIndex: "callerName",
  },
  {
    key: 5,
    title: "Caller Relationship to Patient",
    dataIndex: "callerRelationshipPatient",
  },
  {
    title: "Room Number",
    dataIndex: "roomNumber",
  },
  {
    title: "Nurse Station",
    dataIndex: "nurseStation",
  },
  {
    title: "Call back number",
    dataIndex: "callBackNumber",
  },
  {
    title: "Does the caller need a call back",
    dataIndex: "callerNeedCallback",
  },
  {
    title: "Message",
    dataIndex: "message",
  },
  {
    title: "Message sent to",
    dataIndex: "messageSentTo",
  },
  {
    title: "Call Patched To",
    dataIndex: "callPatchedTo",
  },
  {
    title: "On Call Provider",
    dataIndex: "onCallProvider",
  },
  {
    title: "Provider Response",
    dataIndex: "onCallProviderResponse",
  },
  {
    title: "Attachment Links",
    dataIndex: "attachmentLinks",
  },
  {
    title: "Call Date and Time",
    dataIndex: "providerResponseDateTime",
  },
];

const CEOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Tuck In Call",
    label: "Tuck In Call",
  },
  {
    value: "Therapy Utilization Review",
    label: "Therapy Utilization Review",
  },
  {
    value: "Change in Condition Note",
    label: "Change in Condition Note",
  },
  {
    value: "General Notes",
    label: "General Notes",
  },
  {
    value: "Inpatient Hospital Communication Note",
    label: "Inpatient Hospital Communication Note",
  },
  {
    value: "Admission / Re-Admission",
    label: "Admission / Re-Admission",
  },
  {
    value: "High Risk Assessment",
    label: "High Risk Assessment",
  },
];
const SCPOptions = [
  {
    value: "All",
    label: "All",
  },
  {
    value: "Grievance Template",
    label: "Grievance Template",
  },
  {
    value: "ED Visit Template",
    label: "ED Visit Template",
  },
  {
    value: "Request for Tier Change",
    label: "Request for Tier Change",
  },
  {
    value: "Order / Request Template",
    label: "Order / Request Template",
  },
  {
    value: "DME Request",
    label: "DME Request",
  },
  {
    value: "Nurse Request Template",
    label: "Nurse Request Template",
  },
  {
    value: "Hospital Admission Template",
    label: "Hospital Admission Template",
  },
  {
    value: "Hospital Discharge Template",
    label: "Hospital Discharge Template",
  },
  {
    value: "Outbound Call",
    label: "Outbound Call",
  },

  {
    value: "Admission / Re-Admission",
    label: "Admission / Re-Admission",
  },
];

export {
  TuckInCallColumns,
  TherapyColumns,
  ChangeinConditionColumns,
  NotesColumns,
  IPHospitalCommColumns,
  AdmReAdmColumns,
  HighRiskColumns,
  GrievanceColumns,
  EDColumns,
  TierChangeColumns,
  OrderColumns,
  DMEColumns,
  NurseColumns,
  HospAdmColumns,
  HospDischColumns,
  OutboundColumns,
  TriageColumns,
  CEOptions,
  SCPOptions,
};
