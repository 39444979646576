import React from "react";
import Select from "react-select";

export const getLobColors = (lob) => {
  if (lob === "HN") {
    return "tw-bg-green-100 tw-text-green-700";
  } else if (lob === "SCP") {
    return "tw-bg-blue-100 tw-text-blue-700";
  } else if (lob === "PAC") {
    return "tw-bg-red-100 tw-text-red-700";
  } else {
    return "tw-bg-gray-100 tw-text-gray-700";
  }
};

export const formatOptionLabel = ({ label }, meta) => {
  const isSelected = meta
    ? meta.selectValue[0]?.label === label && meta.context === "menu"
    : "";

  if (!label) {
    return null;
  }
  
  return (
    <div className="tw-flex tw-space-x-3 tw-items-baseline">
      <div className={`${isSelected ? "tw-text-white" : "tw-text-gray-900"}`}>
        {label.split(" - ")[0]}{" "}
      </div>
      <div
        className={`${
          isSelected ? "tw-text-gray-100" : "tw-text-gray-500"
        } tw-text-sm`}
      >
        {label.split(" - ")[1]}
      </div>
      {label.split(" - ")[2] && (
        <div
          className={`tw-py-0.5 tw-px-2 tw-text-xs tw-rounded ${getLobColors(
            label.split(" - ")[2]
          )}`}
        >
          {label.split(" - ")[2]}
        </div>
      )}
      <div
        className={`${
          isSelected ? "tw-text-gray-100" : "tw-text-rose-500"
        } tw-text-sm`}
      >
        {label.split(" - ")[3] ? label.split(" - ")[3] : ""}
      </div>
    </div>
  );
};

const MyCustomSelect = ({
  isRequired,
  mode,
  disable,
  errorMessage,
  selectOptions,
  name,
  marginb,
  selectedValue,
  defaultOption,
  isMulti,
  myCallBack,
  index,
  formatOptionLabel,
  placeholderText,
  menuPortalTarget,
}) => {
  const onChange = (option, actionMeta) => {
    myCallBack(option, index);
  };

  const filterOptions = (input, option) =>
    (input?.label ?? "").toLowerCase().includes(option?.toLowerCase());

  const customStyles = {
    container: (base, state) => ({
      ...base,
      outline: "none",
    }),
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? "blue" : errorMessage ? "red" : "#ddd",
      marginBottom: marginb,
      boxShadow: "none",
      // overwrittes hover style
      "&:hover": {
        borderColor: state.isFocused ? "blue" : errorMessage ? "red" : "#ddd",
      },
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };
  return (
    <div className="form-group ">
      <Select
        isDisabled={disable ? disable : false}
        isMulti={mode}
        isClearable
        key="gender-score-filter"
        className="basic-single"
        classNamePrefix="select"
        name={"gender-score-filter"}
        value={selectedValue}
        options={selectOptions}
        onChange={onChange}
        styles={customStyles}
        filterOption={filterOptions}
        formatOptionLabel={formatOptionLabel}
        placeholder={placeholderText ? placeholderText : "Select..."}
        menuPortalTarget={menuPortalTarget}
      />
      {/* {errorMessage && (
        <span
          className="text-danger"
          style={{ fontWeight: "normal", paddingLeft: "5px" }}
        >
          {errorMessage}
        </span>
      )} */}
    </div>
  );
};

export default MyCustomSelect;
