import React, { useState } from "react";
import useSWR from "swr";
import { fetcherhn } from "../../services/fectcher";
import {
  Button,
  Drawer,
  Popconfirm,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import Search from "antd/es/input/Search";
import AddHighRisk from "./AddHighRisk";
import { WarningOutlined } from "@ant-design/icons";
import { deleteHRA } from "../../api/CareEventsApi";
import { openNotification } from "../../common/elements/myNotification";
import { handleToggle } from "../../common/elements/toggleSeeMore";
import { usePickList } from "../../services/queries";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { FiSend } from "react-icons/fi";
import SendEmail from "../../common/elements/SendEmail";

const HighRisk = (props) => {
  const noMedList = usePickList(24).data;

  const intialReqState = {
    key: "",
    guid: "",
    mbi: props.mbiid,
    assessmentDateTime: dayjs().format("YYYY-MM-DD hh:mm A"),
    summary: "",
    highRiskCriteria: "",
    isCCECompletedThisYear: "",
    cognition: "",
    physician: "",
    nP: "",
    specialist: "",
    family: "",
    dPOA: "",
    eMail: "",
    bestContact: "",
    pOlst: "",
    advancedDirectives: "",
    numberOfMedicationsType: "",
    isErHospitalVisit: "No",
    erHospitalVisitNotes: "",
    isCareCoordinationNeeds: "No",
    careCoordinationNeedsNotes: "",
    isSocialWorkerNeeds: "No",
    socialWorkerNeedsNotes: "",
    notes: "",
    createdBy: sessionStorage.getItem("userName") || "",
    modifiedBy: "",
  };

  const [editRecord, setEditRecord] = useState(intialReqState);
  const [query, setQuery] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data, mutate, isLoading } = useSWR(
    `GetHighRiskAssessment?id=${props.mbiid}`,
    fetcherhn
  );

  const handleCancel = (e) => {
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const [mail, setMail] = useState(false);

  const sendDatatoEmail = (record) => {
    setMail(true);
    setEditRecord(record);
  };

  const handleModalClose = (e) => {
    setEditRecord(intialReqState);
    setMail(false);
  };

  const edit = (record) => {
    console.log("record", record);
    setIsModalOpen(true);
    setEditRecord(record);
  };

  const handleDelete = async (record) => {
    const body = { ...record, modifiedBy: sessionStorage.getItem("userName") };
    const deleteResult = await deleteHRA(body);
    console.log("deleteResult", deleteResult);
    if (deleteResult.status) {
      mutate();
      openNotification(
        "success",
        "Deleted",
        "High Risk Assessment Deleted Successfully!"
      );
    }
  };

  const handleOk = async (e) => {
    if (e) {
      mutate();
    }
    setIsModalOpen(false);
    setEditRecord(intialReqState);
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const columns = [
    {
      key: 1,
      title: "Assessment Date & Time",
      dataIndex: "assessmentDateTime",
      render: (_, { assessmentDateTime, createdBy }) => {
        const [name] = createdBy.split("@");
        return (
          <>
            <div>{dayjs(assessmentDateTime).format("YYYY-MM-DD hh:mm A")}</div>
            <div>{name}</div>
          </>
        );
      },
      width: 180,
    },
    {
      key: 2,
      title: "Summary",
      dataIndex: "summary",
      width: 200,
      render: (text, record) => {
        const isExpanded = expandedRows.includes(record.key);
        const content =
          text.length > 100 ? (
            <span>
              {isExpanded ? text : `${text.substring(0, 100)}...`}
              <Button
                type="link"
                onClick={() => handleToggle(record.key, setExpandedRows)}
              >
                {isExpanded ? "See less" : "See more"}
              </Button>
            </span>
          ) : (
            text
          );

        return <div>{content}</div>;
      },
    },
    {
      key: 3,
      title: "High Risk Criteria",
      dataIndex: "highRiskCriteria",
      width: 100,
    },
    {
      key: 4,
      title: "Was a CCE completed within this year",
      dataIndex: "isCCECompletedThisYear",
      width: 155,
      render: (_, { isCCECompletedThisYear }) => {
        return (
          <>
            {isCCECompletedThisYear === "Yes" ? (
              <span>Yes</span>
            ) : (
              <>
                <div>
                  {isCCECompletedThisYear === "No" ? "No" : "Not found in EMR"}
                  <span className="tw-ml-2" style={{ color: "red" }}>
                    <WarningOutlined />
                  </span>
                </div>
              </>
            )}
          </>
        );
      },
    },
    {
      key: 5,
      title: "Cognition",
      dataIndex: "cognition",
      width: 100,
    },
    {
      key: 6,
      title: "Physician",
      dataIndex: "physician",
      width: 100,
    },
    {
      key: 7,
      title: "NP",
      dataIndex: "nP",
      width: 100,
    },
    {
      key: 8,
      title: "Specialist",
      dataIndex: "specialist",
      width: 100,
    },
    {
      key: 9,
      title: "Family",
      dataIndex: "family",
      width: 100,
    },
    {
      key: 10,
      title: "DPOA",
      dataIndex: "dPOA",
      width: 100,
    },
    {
      key: 11,
      title: "Email",
      dataIndex: "eMail",
      width: 100,
    },
    {
      key: 12,
      title: "Best Contact",
      dataIndex: "bestContact",
      width: 100,
    },
    {
      key: 13,
      title: "POLST",
      dataIndex: "pOlst",
      width: 100,
    },
    {
      key: 14,
      title: "Advanced Directives",
      dataIndex: "advancedDirectives",
      width: 150,
    },
    {
      key: 15,
      title: "Number Of Medications",
      dataIndex: "numberOfMedicationsLabel",
      width: 180,
    },
    {
      key: 16,
      title: "ED / Hospital Visit",
      dataIndex: "isErHospitalVisit",
      width: 140,
    },
    {
      key: 17,
      title: "ED / Hospital Visit Notes",
      dataIndex: "erHospitalVisitNotes",
      width: 180,
    },
    {
      key: 18,
      title: "Care Coordination Needs",
      dataIndex: "isCareCoordinationNeeds",
      width: 190,
    },
    {
      key: 19,
      title: "Care Coordination Needs Notes",
      dataIndex: "careCoordinationNeedsNotes",
      width: 200,
    },
    {
      key: 20,
      title: "Social Worker Needs",
      dataIndex: "isSocialWorkerNeeds",
      width: 150,
    },
    {
      key: 21,
      title: "Social Worker Needs Notes",
      dataIndex: "socialWorkerNeedsNotes",
      width: 200,
    },
    {
      key: 22,
      title: "Notes",
      dataIndex: "notes",
      width: 100,
    },

    // {
    //     key: 23,
    //     title: "Note Date & Time",
    //     width: 170,
    //     render: (_, { noteDateTime, createdBy }) => {
    //         const [name] = createdBy.split("@");
    //         return (
    //             <>
    //                 <div>{dayjs(noteDateTime).format("YYYY-MM-DD hh:mm A")}</div>
    //                 <div>{name}</div>
    //             </>
    //         );
    //     },
    // },
    {
      key: "key",
      title: "Operation",
      dataIndex: "guid",
      fixed: "right",
      width: 110,
      render: (_, record) => (
        <div>
          <Space>
            <Tooltip title="Edit">
              <FaEdit
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => edit(record)}
                color="blue"
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Popconfirm
                title="Ok to Delete?"
                onConfirm={() => handleDelete(record)}
                icon={
                  <WarningOutlined
                    style={{
                      color: "red",
                    }}
                  />
                }
              >
                <FaRegTrashAlt
                  size={20}
                  className=" tw-cursor-pointer"
                  color="red"
                />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Send Email">
              <FiSend
                size={20}
                className=" tw-cursor-pointer"
                onClick={() => sendDatatoEmail(record)}
                color="green"
              />
            </Tooltip>
          </Space>
        </div>
      ),
    },
  ];

  const finalResult = data
    ? data.filter((item) => {
        return [
          "summary",
          "assessmentDateTime",
          "highRiskCriteria",
          "isCCECompletedThisYear",
          "cognition",
          "physician",
          "nP",
          "specialist",
          "family",
          "dPOA",
          "eMail",
          "bestContact",
          "pOlst",
          "advancedDirectives",
          "numberOfMedicationsLabel",
          "isErHospitalVisit",
          "erHospitalVisitNotes",
          "isCareCoordinationNeeds",
          "careCoordinationNeedsNotes",
          "isSocialWorkerNeeds",
          "socialWorkerNeedsNotes",
          "notes",
          "createdBy",
        ].some((key) =>
          (item[key]?.toString()?.toLowerCase() ?? "").includes(
            query?.toLowerCase() ?? ""
          )
        );
      })
    : data;

  if (isLoading) {
    return <Skeleton active={isLoading} />;
  }

  return (
    <div>
      <div className="tw-flex tw-justify-between ">
        <p
          className="pt-3"
          style={{
            color: "#D2691E",
            fontWeight: "600",
          }}
        >
          High Risk Assessment
        </p>

        <div className="tw-flex tw-justify-end  tw-mt-3 ">
          <Space>
            <Search
              className=" tw-mb-4"
              size="middle"
              placeholder="Search"
              onChange={(e) => setQuery(e.target.value)}
              allowClear
            />
            <Button
              type="primary"
              onClick={() => setIsModalOpen(true)}
              className="tw-mb-4 "
            >
              Add
            </Button>
          </Space>
        </div>
      </div>
      {isModalOpen && (
        <Drawer
          open={isModalOpen}
          title={"Add/Update High Risk Assessment"}
          onClose={handleCancel}
          width={550}
        >
          <AddHighRisk
            mbiid={props.mbiid}
            editRecord={editRecord}
            callback={handleOk}
            noMedList={noMedList}
          />
        </Drawer>
      )}
      <Table
        virtual
        bordered
        size="middle"
        dataSource={finalResult}
        columns={columns}
        scroll={{
          x: 2000,
          y: 1000,
        }}
      />

      {mail && (
        <SendEmail
          open={mail}
          close={handleModalClose}
          editRecord={editRecord}
          modalTitle={"High Risk Assessment"}
          patientName={props?.patient?.label || ""}
        />
      )}
    </div>
  );
};

export default HighRisk;
